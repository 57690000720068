import { EType } from '../repositories/journal/entities/journal/JournalAttributesSchema';

export const PostTypeUrls: { [key in EType]: string } = {
  [EType.Articles]: '/magazine/',
  [EType.Blogs]: '/blogs/',
  [EType.News]: '/novosti/',
  [EType.Questions]: '/forum-rieltorov/',
  [EType.Quizzes]: '/magazine/',
};

export const PostTypeNames: { [key in EType]: string } = {
  [EType.Articles]: 'Статьи',
  [EType.Blogs]: 'Блоги',
  [EType.News]: 'Новости',
  [EType.Questions]: 'Вопросы',
  [EType.Quizzes]: 'Опросы',
};

export const PostTypePostCardUrlPrefixes: { [key in EType]: string } = {
  [EType.Articles]: 'stati',
  [EType.Blogs]: 'blogs',
  [EType.News]: 'novosti',
  [EType.Questions]: 'forum-rieltorov',
  [EType.Quizzes]: 'quizzes',
};

export const PostTypeAsContentTypeModel: { [key in EType]: string } = {
  [EType.Articles]: 'articles',
  [EType.Blogs]: 'blog',
  [EType.News]: 'news',
  [EType.Questions]: 'question',
  [EType.Quizzes]: 'quizzes',
};
