import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IPostAttributes } from '../../../types/post';

export enum ESetPostAttributeActionType {
  Set = 'post/setPostAttribute/set',
}

export interface ISetPostAttribute<P extends keyof IPostAttributes = keyof IPostAttributes> {
  key: P;
  value?: IPostAttributes[P];
}

export type TSetPostAttribute = ITypedReduxAction<ESetPostAttributeActionType.Set, ISetPostAttribute>;
