import React from 'react';

import * as s from './PostLikesLayout.css';

/**
 * Декоратор для компонентов PostLikesContainer и TelegramLink
 */
export const PostLikesLayout = ({ children }: { children: React.ReactNode }) => (
  <div className={s['wrapper']}>{children}</div>
);
