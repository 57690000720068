import React from 'react';

import { IconFunctionalSuccessCircle24 } from '../../../Icons';
import * as s from './SubscribeSucceedTooltipContent.css';

interface ISubscribeSucceedTooltip {
  email: string;
}

/**
 * Содержимое тултипа завершения подписки
 */
export const SubscribeSucceedTooltipContent = ({ email }: ISubscribeSucceedTooltip) => {
  return (
    <div className={s['wrapper']}>
      <IconFunctionalSuccessCircle24 color={'white_100'} />
      Пришлём новые комментарии
      <br />
      на {email}
    </div>
  );
};
