import React from 'react';
import { Spinner } from '@cian/ui-kit';

import { UserCardForAuthorObject } from '../../../UserCard';
import { TLikedUsersListState } from '../../../../types/commentList';
import { ERequestStatus } from '../../../../types/requestStatus';
import { useEffectOnce } from '../../../../utils';
import * as s from './LikesModalTabContent.css';

interface ILikesModalTabContent extends Partial<TLikedUsersListState> {
  onTabShown?(): void;
}

export const LikesModalTabContent = ({ status, items: users, onTabShown }: ILikesModalTabContent) => {
  const hasContent = status === ERequestStatus.Succeed && users?.length;

  useEffectOnce(() => {
    if (onTabShown) {
      onTabShown();
    }
  });

  return (
    <div className={s['wrapper']}>
      {!hasContent && (
        <div className={s['spinner-wrapper']}>
          <Spinner size={40} />
        </div>
      )}

      {hasContent && users.map((user, index) => <UserCardForAuthorObject key={index} {...user} userTrust={false} />)}
      {hasContent && <div className={s['delimiter']} />}
    </div>
  );
};
