import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addLike, getLikedUsers } from '../../../../actions/postComments';
import { TThunkDispatch } from '../../../../types/redux';
import { findByIdRecursive, useRequestAuthentication } from '../../../../utils';
import { ILikesComment, ILikesToggleTooltip } from '../../../../components/PostCommentList';
import { selectComments } from '../../../../selectors/postCard';
import { ERequestStatus } from '../../../../types/requestStatus';
import { LikeTypeLikedUsers } from '../../../../constants';

export function useLikeComment() {
  const dispatch = useDispatch<TThunkDispatch>();
  const checkAuth = useRequestAuthentication();
  const { items: comments } = useSelector(selectComments);

  /**
   * Отправляет лайк/дизлайк на бэк или отправляет на авторизацию
   */
  const handleLikeClick = useCallback(
    async ({ id, type }: ILikesComment) =>
      checkAuth() &&
      dispatch(
        addLike({
          commentId: id,
          likeType: type,
        }),
      ),
    [checkAuth, dispatch],
  );

  /**
   * Запрашивает список пользователей,
   * которые лайкнули/дизлайкнули коммент,
   */
  const handleToggleLikedUsers = useCallback(
    async ({ id, isShown, type }: ILikesToggleTooltip) => {
      if (!isShown) {
        return;
      }

      const currentComment = findByIdRecursive(comments, id);

      if (!currentComment) {
        return;
      }

      const status = currentComment?.[LikeTypeLikedUsers[type]]?.status ?? ERequestStatus.Initial;

      // Запрашиваем только один раз для конкретного коммента
      if (status !== ERequestStatus.Initial) {
        return;
      }

      return dispatch(getLikedUsers({ commentId: id, likeType: type }));
    },
    [comments, dispatch],
  );

  return { handleLikeClick, handleToggleLikedUsers };
}
