import { IUser } from './user';

/** Типа лайка */
export enum ELikeType {
  Like = 1,
  Dislike = -1,
}

/**
 * @deprecated
 */
export interface ILike {
  type: string;
  id: string;
  attributes: {
    user: IUser;
    vote: 1 | -1;
    createdAt: string;
    objectPk: number;
  };
}
