import React from 'react';
import { Like16 as IconSocialLikeOn16, Button, UIHeading4 } from '@cian/ui-kit';

import { IconSocialDislikeOff16 } from '../Icons';
import * as s from './PostLikes.css';

interface IPostLikes {
  onLikeClick(e: React.MouseEvent): void;
  onDislikeClick(e: React.MouseEvent): void;
}

/**
 * Блок лайков для поста
 */
export const PostLikes = ({ onLikeClick, onDislikeClick }: IPostLikes) => (
  <div className={s['wrapper']}>
    <UIHeading4>Понравилась статья?</UIHeading4>

    <div className={s['buttons']}>
      <Button
        beforeIcon={<IconSocialLikeOn16 color={'white_100'} />}
        theme={'fill_primary'}
        fullWidth
        onClick={onLikeClick}
      >
        Да
      </Button>

      <Button
        beforeIcon={<IconSocialDislikeOff16 color={'primary_100'} />}
        theme={'stroke_primary'}
        fullWidth
        onClick={onDislikeClick}
      >
        Нет
      </Button>
    </div>
  </div>
);
