// Должен соответствовать projectName
/* istanbul ignore next */
export const UNIQUE_CONFIG_KEY = 'frontend-content';

/** Значение параметра limit для листингов по умолчанию */
export const LISTING_REQUEST_LIMIT = 18;

/** Значение параметра limit для новостей на Главной */
export const MAIN_NEWS_REQUEST_LIMIT = 10;

/** Значение параметра limit для листинга статей на Главной */
export const MAIN_ARTICLES_REQUEST_LIMIT = LISTING_REQUEST_LIMIT;

/** Значение параметра limit для листинга статей */
export const ARTICLE_LIST_REQUEST_LIMIT = LISTING_REQUEST_LIMIT;

/** Значение параметра limit для листинга новостей */
export const NEWS_LIST_REQUEST_LIMIT = LISTING_REQUEST_LIMIT;

/** Значение параметра limit для листинга постов блогов */
export const BLOG_LIST_REQUEST_LIMIT = LISTING_REQUEST_LIMIT;

/** Значение параметра limit для листинга вопросов */
export const QUESTION_LIST_REQUEST_LIMIT = LISTING_REQUEST_LIMIT;

/** Значение параметра limit для листинга поиска */
export const SEARCH_LIST_REQUEST_LIMIT = LISTING_REQUEST_LIMIT;

/** Значение параметра limit для поиска в модалке */
export const QUICK_SEARCH_LIST_REQUEST_LIMIT = 10;

/** Значение параметра limit для списка рекомендованных оферов вв контенте поста */
export const POST_CONTENT_RECOMMENDED_OFFERS_REQUEST_LIMIT = 10;

/** Значение параметра limit для дискуссий в карточке поста */
export const POST_CARD_DISCUSSIONS_REQUEST_LIMIT = 4;

/** Значение параметра limit для новостей в карточке поста */
export const POST_CARD_NEWS_REQUEST_LIMIT = 4;

/** Значение параметра endDate для новостей в карточке поста */
export const POST_CARD_NEWS_REQUEST_END_DATE = 7;

/** Значение параметра limit для комментариев в карточке поста */
export const POST_CARD_COMMENTS_REQUEST_LIMIT = 10;

/** Урл Циана */
export const CIAN_URL = '//www.cian.ru';

/** Минимальный номинал рейтинга пользователя для доступа к закрытым страницам */
export const MIN_RATING_LIMIT = 50;

/** Страницы, которые поддерживают query-параметр with-tag */
export const QS_WITH_TAG_SUPPORTING_PAGE_PATHS = ['/magazine/'];

/** Страницы, которые поддерживают query-параметр tag */
export const QS_TAG_SUPPORTING_PAGE_PATHS = ['/magazine/', '/stati/', '/novosti/', '/blogs/', '/forum-rieltorov/'];

/** Страницы, которые поддерживают query-параметр search */
export const QS_SEARCH_SUPPORTING_PAGE_PATHS = ['/magazine/', '/stati/', '/novosti/', '/blogs/', '/forum-rieltorov/'];

export enum FindRealtorUTM {
  Listing = 'MagazineForumRieltorovListing',
}

/** Причины отклонения поста (блога) */
export const POST_DECLINE_REASONS = {
  ad_spam: 'Реклама, спам',
  obscene_language: 'Нецензурная лексика',
  rules_violation: 'Нарушение правил сайта',
};

export const DOWNLOAD_ALL_DOCUMENTS_LINK =
  'https://content.cdn-cian.ru/realty/uploads/content/documents/vse-shablony.zip';

export const CIAN_OFFICIAL_TELEGRAM_LINK = 'https://t.me/cian_official';
