import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addComment } from '../../../../actions/postComments';
import { selectPost } from '../../../../selectors/post';
import { useRequestAuthentication } from '../../../../utils';
import { TThunkDispatch } from '../../../../types/redux';

interface IAddComment {
  value: string;
  isChecked: boolean;
  parentCommentId?: number;
}

/**
 * Функционал работы с формой добавления комментария
 */
export function useAddComment() {
  const dispatch = useDispatch<TThunkDispatch>();
  const [formValue, setFormValue] = useState('');
  const [isFormExpanded, setIsFormExpanded] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(true);
  const post = useSelector(selectPost);
  const checkAuth = useRequestAuthentication();

  /**
   * Очищает форму и сбрасывает ее состояния
   */
  const resetForm = useCallback(() => {
    setIsFormExpanded(false);
    setFormValue('');
    setIsSubscriptionChecked(true);
    setIsFormDisabled(false);
  }, []);

  /**
   * Обработчик события добавления комментария onAddComment
   * Добавляет комментарий или отправляет на авторизацию
   */
  const handleAddComment = useCallback(
    async ({ value, isChecked, parentCommentId }: IAddComment) => {
      if (!('id' in post)) {
        return false;
      }

      return (
        checkAuth() &&
        dispatch(
          addComment({
            postType: post.attributes.type,
            isSubscriptionChecked: isChecked,
            postId: post.id,
            parentCommentId,
            comment: value,
          }),
        )
      );
    },
    [checkAuth, dispatch, post],
  );

  /**
   * Обработчик события раскрытия формы onToggleExpanded.
   * Раскрывает форму или отправляет на авторизацию
   */
  const handleToggleExpanded = useCallback((value: boolean) => checkAuth() && setIsFormExpanded(value), [checkAuth]);

  /**
   * Обработчик события отправки формы onSubmit.
   * Отправляет коммент или отправляет на авторизацию
   */
  const handleSubmit = useCallback(
    async (value, isChecked) => {
      setIsFormDisabled(true);

      if (await handleAddComment({ value, isChecked })) {
        return resetForm();
      }

      setIsFormDisabled(false);
    },
    [handleAddComment, resetForm],
  );

  /**
   * Обработчик события изменения textarea формы onChangeValue
   * Обновляет состояние значения textarea
   */
  const handleChangeValue = useCallback((value: typeof formValue) => setFormValue(value), []);

  /**
   * Обработчик события изменения чекбокса подписки onChangeSubscriptionChecked.
   * Обновляет состояние значения чекбокса подписки
   */
  const handleChangeSubscriptionChecked = useCallback(
    (value: typeof isSubscriptionChecked) => setIsSubscriptionChecked(value),
    [],
  );

  return {
    handleToggleExpanded,
    handleSubmit,
    handleUnmount: resetForm,
    handleAddComment,
    handleChangeValue,
    handleChangeSubscriptionChecked,
    formValue,
    isFormExpanded,
    isSubscriptionChecked,
    isFormDisabled,
  };
}
