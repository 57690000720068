import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { removeComment, removeCommentSucceed } from '../../../../actions/postComments';
import { TThunkDispatch } from '../../../../types/redux';
import { useRequestAuthentication } from '../../../../utils';

export function useRemoveComment() {
  const dispatch = useDispatch<TThunkDispatch>();
  const checkAuth = useRequestAuthentication();

  /**
   * Запускает экшен removeComment или отправляет на авторизацию
   */
  const remove = useCallback(async (id: number) => checkAuth() && dispatch(removeComment(id)), [checkAuth, dispatch]);

  /**
   * Запускает экшен removeCommentSucceed или отправляет на авторизацию
   */
  const clearStore = useCallback(
    (id: number) => checkAuth() && dispatch(removeCommentSucceed(id)),
    [checkAuth, dispatch],
  );

  return { remove, clearStore };
}
