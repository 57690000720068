import React from 'react';
import { UIText2 } from '@cian/ui-kit';

import { ICommentParent } from '../../../../types/commentList';
import { UserCardForAuthorObject } from '../../../UserCard';
import { DateTimePublished } from '../../../DatePublished';
import { AuthorLabel } from '../Labels';
import * as s from './UserCard.css';

export interface IUserCard extends Pick<ICommentParent, 'authorObject'> {
  datePublished: ICommentParent['datePublish'];
  isAuthorLabelShown: boolean;
}

/**
 * Карточка пользователя для коммента
 */
export const UserCard = ({ datePublished, authorObject, isAuthorLabelShown }: IUserCard) =>
  authorObject?.userId ? (
    <UserCardForAuthorObject
      {...authorObject}
      size={'L'}
      isStrongName={true}
      afterName={
        isAuthorLabelShown && (
          <div className={s['author-label']}>
            <AuthorLabel />
          </div>
        )
      }
      afterAll={
        <UIText2 color={'gray60_100'}>
          <DateTimePublished>{datePublished}</DateTimePublished>
        </UIText2>
      }
    />
  ) : null;
