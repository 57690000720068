import React from 'react';

import { IListItem, ListItem } from './components/ListItem';
import { ICommentParent, IComment } from '../../types/commentList';
import { ELikeType } from '../../types/likes';
import * as s from './PostCommentList.css';

interface IAddComment {
  parentCommentId: number;
  value: string;
  isChecked: boolean;
}

interface IEditComment {
  id: number;
  value: string;
}

export interface ILikesComment {
  id: number;
  type: ELikeType;
}

export interface ILikesToggleTooltip {
  id: number;
  isShown: boolean;
  type: ELikeType;
}

export interface IPostCommentList {
  items: ICommentParent[] | IComment[];
  onAddComment({ parentCommentId, value, isChecked }: IAddComment): Promise<boolean | void>;
  level?: number;
  postAuthorId?: IListItem['postAuthorId'];
  onExpandForm?(e: React.MouseEvent): boolean | void;
  onLikesClick?({ id, type }: ILikesComment): Promise<boolean | void>;
  onToggleLikedUsers?({ id, isShown, type }: ILikesToggleTooltip): Promise<boolean | void>;
  onEditButtonClick?(e: React.MouseEvent): boolean | void;
  onEditComment?({ id, value }: IEditComment): Promise<boolean | void>;
  onRemoveButtonClick?(itemId: number): Promise<boolean | void>;
  onRemoveComment?(itemId: number): void;
  onResetIsJustAdded?(itemId: number): void;
}

/**
 * Список комментариев
 */
export const PostCommentList = ({
  items,
  onAddComment,
  level = 1,
  onLikesClick,
  onToggleLikedUsers,
  onEditComment,
  onRemoveButtonClick,
  onRemoveComment,
  onResetIsJustAdded,
  ...postCommentListProps
}: IPostCommentList) => {
  return (
    <div className={s['wrapper']}>
      {items.map(
        ({
          id,
          datePublish,
          authorObject,
          bestAdvice,
          comment,
          likesCount,
          dislikesCount,
          isJustAdded,
          likedUsers,
          dislikedUsers,
          ...props
        }) => {
          return (
            <ListItem
              key={id}
              isJustAdded={isJustAdded}
              isBestAdvice={bestAdvice}
              isChild={level > 1}
              datePublished={datePublish}
              authorObject={authorObject}
              likesCount={likesCount}
              dislikesCount={dislikesCount}
              likedUsers={likedUsers}
              dislikedUsers={dislikedUsers}
              comment={comment}
              onAddComment={(value, isChecked) => onAddComment({ parentCommentId: id, value, isChecked })}
              onLikesClick={onLikesClick && (type => onLikesClick({ id, type }))}
              onToggleLikedUsers={onToggleLikedUsers && (args => onToggleLikedUsers({ id, ...args }))}
              onEditComment={onEditComment && (value => onEditComment({ id, value }))}
              onRemoveButtonClick={onRemoveButtonClick && (() => onRemoveButtonClick(id))}
              onRemoveComment={onRemoveComment && (() => onRemoveComment(id))}
              onResetIsJustAdded={onResetIsJustAdded && (() => onResetIsJustAdded(id))}
              {...postCommentListProps}
            >
              {'comments' in props && props?.comments?.length && (
                <PostCommentList
                  items={props.comments}
                  level={level + 1}
                  onAddComment={onAddComment}
                  onLikesClick={onLikesClick}
                  onToggleLikedUsers={onToggleLikedUsers}
                  onRemoveButtonClick={onRemoveButtonClick}
                  onRemoveComment={onRemoveComment}
                  onResetIsJustAdded={onResetIsJustAdded}
                  {...postCommentListProps}
                />
              )}
            </ListItem>
          );
        },
      )}
    </div>
  );
};
