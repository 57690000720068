import * as React from 'react';
import { UIText2 } from '@cian/ui-kit';

import { ETabs } from '../../PostTabsContainer';
import * as s from './Tabs.css';

interface ITabs {
  activeTab: ETabs;
  hasAuthors: boolean;
  hasTags: boolean;
  onChange(activeTab: ETabs): void;
  children?: React.ReactNode;
}

/**
 * Табы авторов и тегов
 */
export const Tabs = ({ activeTab, hasTags, hasAuthors, onChange, children }: ITabs) => {
  return (
    <div className={s['wrapper']}>
      <div className={s['tabs-wrapper']}>
        {hasAuthors && (
          <div
            className={`${s['tab']} ${activeTab === ETabs.Authors ? s['_active'] : ''}`}
            onClick={() => onChange(ETabs.Authors)}
          >
            <UIText2 fontWeight={'bold'} color={activeTab === ETabs.Authors ? 'primary_100' : 'black_100'}>
              Авторы
            </UIText2>
          </div>
        )}

        {hasTags && (
          <div
            className={`${s['tab']} ${activeTab === ETabs.Tags ? s['_active'] : ''}`}
            onClick={() => onChange(ETabs.Tags)}
          >
            <UIText2 fontWeight={'bold'} color={activeTab === ETabs.Tags ? 'primary_100' : 'black_100'}>
              Теги
            </UIText2>
          </div>
        )}
      </div>

      {children && <div className={s['tabs-after']}>{children}</div>}
    </div>
  );
};
