import { TThunkAction } from '../../../types/redux';
import { fetchAddLike, TAddLikeResponse, IAddLikeRequest } from '../../../services/likes/addLike';
import { throwError } from '../../../utils';
import { addLikeSucceed } from './addLikeSucceed';
import { ELikeType } from '../../../types/likes';

interface IAddLike {
  commentId: IAddLikeRequest['object_pk'];
  likeType: ELikeType;
}

export const addLike = ({ commentId, likeType }: IAddLike): TThunkAction<Promise<boolean>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    try {
      const { response, statusCode }: TAddLikeResponse = await fetchAddLike({
        httpApi,
        parameters: {
          content_type_model: 'threadedcomment',
          object_pk: commentId,
          vote: likeType,
        },
      });

      if (statusCode !== 201) {
        throwError({ message: 'Не удалось добавить лайк', statusCode, domain: 'actions.addLike' });

        return false;
      }

      const {
        attributes: { dislikesCount, likesCount },
      } = response.data;

      dispatch(
        addLikeSucceed({
          commentId,
          dislikesCount,
          likesCount,
        }),
      );

      return true;
    } catch (e) {
      logger.error(e, {
        domain: 'actions.addLike',
      });

      return false;
    }
  };
};
