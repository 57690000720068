import React from 'react';

import * as s from './PostTabsLayout.css';

/**
 * Декоратор для контейнера PostTabsContainer
 */
export const PostTabsLayout = ({ children }: { children: React.ReactNode }) => (
  <div className={s['wrapper']}>{children}</div>
);
