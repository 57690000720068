import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useRequestAuthentication } from '../../../../utils';
import { addLike } from '../../../../actions/post';
import { TThunkDispatch } from '../../../../types/redux';
import { ELikeType } from '../../../../types/likes';

interface ILike {
  postId: number;
  likeType: ELikeType;
}

export function useLikes() {
  const dispatch = useDispatch<TThunkDispatch>();
  const checkAuth = useRequestAuthentication();

  /**
   * Отправляет лайк/дизлайк на бэк или отправляет на авторизацию
   */
  return useCallback(
    async ({ postId, likeType }: ILike) =>
      checkAuth() &&
      dispatch(
        addLike({
          postId,
          likeType,
        }),
      ),
    [checkAuth, dispatch],
  );
}
