/**
 * Рекурсивно ищет до первого совпадения и возвращает из коллекции объект с заданным id
 * Поддерживает как плоскую структуру "[{ id: 1 }, ..., { id: 20 }]",
 * так и вложенную "[{ id: 1 }, { id: 2, items: [{ id: 3 }, { id: 4, items: [{ id: 5 }] }] }]"
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findByIdRecursive = <T extends { [key: string]: any }>(items: T[], id: number): T | undefined => {
  let result: T | undefined;

  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (item?.id === id) {
      result = item;

      break;
    }

    if (item?.items?.length) {
      result = findByIdRecursive(item?.items, id);

      if (result) {
        break;
      }
    }
  }

  return result;
};
