import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PostCommentsHeaderContainer } from '../PostCommentsHeader';
import { PaginationContainer } from '../Pagination';
import { PostCommentsLayout } from '../../components/PostCommentsLayout';
import { AddCommentForm } from '../../components/PostCommentForms';
import { PostCommentList } from '../../components/PostCommentList';
import { getPostComments, resetPostComments } from '../../actions/postComments';
import { selectPost } from '../../selectors/post';
import { selectComments } from '../../selectors/postCard';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import {
  useEffectOnce,
  useGetListing,
  useRemoveWrongSearchParams,
  useRequestAuthentication,
  useSearchParams,
} from '../../utils';
import { TThunkDispatch } from '../../types/redux';
import { POST_CARD_COMMENTS_REQUEST_LIMIT } from '../../constants';
import { EOrdering, ESortOrder } from '../../repositories/journal/v1/get-comments';
import { useEditComment, useLikeComment, useRemoveComment, useAddComment, useSetCommentParam } from './utils';

export const PostCommentsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { items } = useSelector(selectComments);
  const post = useSelector(selectPost);
  const appendType = useSelector(selectListingAppendType);
  const { page: qsPage } = useSearchParams<{ page: number }>(['page']);
  const { remove: handleRemoveButtonClick, clearStore: handleRemoveComment } = useRemoveComment();
  const handleEditComment = useEditComment();
  const handleSetCommentParam = useSetCommentParam();
  const { handleLikeClick, handleToggleLikedUsers } = useLikeComment();
  const {
    handleToggleExpanded,
    handleSubmit,
    handleUnmount,
    handleAddComment,
    handleChangeValue,
    handleChangeSubscriptionChecked,
    formValue,
    isFormExpanded,
    isFormDisabled,
    isSubscriptionChecked,
  } = useAddComment();
  const checkAuth = useRequestAuthentication();

  useRemoveWrongSearchParams(['page']);
  useGetListing(
    () => {
      if (!('id' in post)) {
        return;
      }

      dispatch(
        getPostComments({
          postId: post.id,
          setType: appendType,
          offset: (qsPage - 1) * POST_CARD_COMMENTS_REQUEST_LIMIT,
          limit: POST_CARD_COMMENTS_REQUEST_LIMIT,
          ordering: EOrdering.Published_at,
          sortOrder: ESortOrder.Desc,
          withCommentId: undefined,
        }),
      ).finally();
    },
    {
      dependencyNames: ['page', 'ordering'],
      eventTrackerData: {
        page: {
          extra: {},
        },
      },
      scrollOptions: {
        type: 'ref',
        ref: wrapperRef,
      },
    },
  );

  /**
   * При размонтировании очищает форму и комменты
   */
  useEffectOnce(() => {
    return () => {
      handleUnmount();
      dispatch(resetPostComments());
    };
  });

  if (!('id' in post)) {
    return null;
  }

  return (
    <PostCommentsLayout ref={wrapperRef}>
      <PostCommentsHeaderContainer />

      <AddCommentForm
        value={formValue}
        isExpanded={isFormExpanded}
        isDisabled={isFormDisabled}
        isSubscriptionChecked={isSubscriptionChecked}
        onSubmit={handleSubmit}
        onToggleExpanded={handleToggleExpanded}
        onChangeValue={handleChangeValue}
        onChangeSubscriptionChecked={handleChangeSubscriptionChecked}
      />

      <PostCommentList
        items={items}
        postAuthorId={post.attributes.authorId}
        onAddComment={handleAddComment}
        onExpandForm={checkAuth}
        onLikesClick={handleLikeClick}
        onToggleLikedUsers={handleToggleLikedUsers}
        onEditButtonClick={checkAuth}
        onEditComment={handleEditComment}
        onRemoveButtonClick={handleRemoveButtonClick}
        onRemoveComment={handleRemoveComment}
        onResetIsJustAdded={commentId => handleSetCommentParam({ id: commentId, key: 'isJustAdded' })}
      />

      <PaginationContainer />
    </PostCommentsLayout>
  );
};
