import React, { CSSProperties } from 'react';
import { Avatar, IAvatarProps } from '@cian/ui-kit';

import * as s from './AvatarStack.css';

type TAvatarStackItem = Pick<IAvatarProps, 'src' | 'href'>;

interface IAvatarStack {
  items: TAvatarStackItem[];
  size?: number;
  marginLeft?: CSSProperties['marginLeft'];
  target?: string;
}

/**
 * Список аватарок в виде стопки карт
 */
export const AvatarStack = ({ items, size = 30, target = '_blank', marginLeft = -12 }: IAvatarStack) => (
  <div className={s['wrapper']}>
    {items.reverse().map((item, index) => (
      <div key={index} className={s['item-wrapper']} style={items.length > index + 1 ? { marginLeft } : {}}>
        <Avatar type="user" size={size} target={target} {...item} />
      </div>
    ))}
  </div>
);
