/* eslint-disable */
import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';

import {
  TUnsubscribeFromCommentsModel,
  IUnsubscribeFromCommentsRequest,
  TUnsubscribeFromCommentsResponse,
} from './types';

const defaultConfig: TUnsubscribeFromCommentsModel = {
  apiType: 'public',
  requestType: 'formData',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'backend-content',
  pathApi: '/v1/unsubscribe-comment',
  hostType: 'api',
};

function createUnsubscribeFromCommentsModel(
  parameters: IUnsubscribeFromCommentsRequest,
): TUnsubscribeFromCommentsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IUnsubscribeFromCommentsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IUnsubscribeFromCommentsRequest;
  config?: IHttpApiFetchConfig;
}

async function fetchUnsubscribeFromComments<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
}: IUnsubscribeFromCommentsOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TUnsubscribeFromCommentsResponse
> {
  return await httpApi.fetch(createUnsubscribeFromCommentsModel(parameters), {
    ...config,
    requestConfig: { withCredentials: true },
  });
}

export { defaultConfig, createUnsubscribeFromCommentsModel, fetchUnsubscribeFromComments };
