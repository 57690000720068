import React, { useCallback, useRef, useState } from 'react';
import { CustomTooltip, Popover } from '@cian/ui-kit';

import { ISubscribeFormSubmit, SubscribeForm } from '../SubscribeForm';
import { PostCommentsNotifySwitcher } from '../../../PostCommentsNotifySwitcher';
import { SubscribeSucceedTooltipContent } from '../SubscribeSucceedTooltipContent';
import { UnsubscribeSucceedTooltipContent } from '../UnsubscribeSucceedTooltipContent';
import { useEffectOnce, useIsMounted, useTimeout } from '../../../../utils';
import * as s from '../../PostCommentsHeader.css';

type TSubscribe = Partial<ISubscribeFormSubmit>;

export interface ISubscribe {
  isSubscriptionChecked: boolean;
  isUserAuthenticated: boolean;
  onSubscribe({ email, isChecked }: TSubscribe): Promise<boolean>;
  onUnsubscribe(): Promise<boolean | void>;
  userEmail?: string;
  isDisabled?: boolean;
}

/**
 * Подписка на комментарии
 */
export const Subscribe = ({
  isSubscriptionChecked,
  isUserAuthenticated,
  onSubscribe,
  onUnsubscribe,
  userEmail,
  isDisabled,
}: ISubscribe) => {
  const switcherWrapperRef = useRef<HTMLLabelElement>(null);
  const [email, setEmail] = useState(userEmail || '');
  const [isNotifySwitcherChecked, setIsNotifySwitcherChecked] = useState(isSubscriptionChecked);
  const [isNotifySwitcherDisabled, setIsNotifySwitcherDisabled] = useState(false);
  const [isSubscribeFormDisabled, setIsSubscribeFormDisabled] = useState(false);
  const [isSubscribeFormShown, setIsSubscribeFormShown] = useState(false);
  const [isSubscribedTooltipShown, setIsSubscribedTooltipShown] = useState(false);
  const [isUnsubscribedTooltipShown, setIsUnsubscribedTooltipShown] = useState(false);
  const isMounted = useIsMounted();

  const resetPopups = useCallback(() => {
    setIsSubscribedTooltipShown(false);
    setIsUnsubscribedTooltipShown(false);
    setIsSubscribeFormShown(false);
  }, []);

  useEffectOnce(() => {
    return () => {
      setIsNotifySwitcherChecked(isSubscriptionChecked);
      resetPopups();
    };
  });

  /**
   * Скрывает финальный тултип подписки автоматически,
   * если он не был закрыт за 3 сек
   */
  useTimeout(
    () => {
      if (!isMounted || !isSubscribedTooltipShown) {
        return;
      }

      setIsSubscribedTooltipShown(false);
    },
    isSubscribedTooltipShown ? 3000 : 0,
  );

  /**
   * Скрывает финальный тултип отписки автоматически,
   * если он не был закрыт за 3 сек
   */
  useTimeout(
    () => {
      if (!isMounted || !isUnsubscribedTooltipShown) {
        return;
      }

      setIsUnsubscribedTooltipShown(false);
    },
    isUnsubscribedTooltipShown ? 3000 : 0,
  );

  /**
   * Обрабатывает сценарии при переключении свитчера подписки
   */
  const handleNotifySwitcher = useCallback(
    async (isChecked: boolean) => {
      setIsNotifySwitcherChecked(isChecked);
      resetPopups();

      /**
       * Пользователь отписывается
       */
      if (!isChecked) {
        setIsNotifySwitcherDisabled(true);

        const isUnsubscribed = await onUnsubscribe();

        setIsUnsubscribedTooltipShown(Boolean(isUnsubscribed));
        setIsNotifySwitcherChecked(!isUnsubscribed);
        setIsNotifySwitcherDisabled(false);

        return;
      }

      /**
       * Авторизованный пользователь подписывается
       * (isChecked && isUserAuthenticated)
       */
      if (isUserAuthenticated) {
        setIsNotifySwitcherDisabled(true);

        const isSubscribed = await onSubscribe({ isChecked: true });

        setIsSubscribedTooltipShown(isSubscribed);
        setIsNotifySwitcherChecked(isSubscribed);
        setIsNotifySwitcherDisabled(false);

        return;
      }

      /**
       * Неавторизованный пользователь подписывается
       * (isChecked && !isUserAuthenticated)
       */
      setIsSubscribeFormShown(true);

      return;
    },
    [isUserAuthenticated, onSubscribe, onUnsubscribe, resetPopups],
  );

  /**
   * Обрабатывает отправку формы подписки
   */
  const handleSubscribeFormSubmit = useCallback(
    async ({ email, isChecked }) => {
      setEmail(email);
      setIsNotifySwitcherDisabled(true);
      setIsSubscribeFormDisabled(true);

      const isSubscribed = await onSubscribe({ email, isChecked });

      setIsSubscribeFormShown(false);
      setIsSubscribedTooltipShown(isSubscribed);
      setIsNotifySwitcherChecked(isSubscribed);
      setIsNotifySwitcherDisabled(false);
      setIsSubscribeFormDisabled(false);
    },
    [onSubscribe],
  );

  /**
   * Обрабатывает закрытие формы подписки
   */
  const handleSubscribeFormClose = useCallback(() => {
    setIsSubscribeFormShown(false);
    setIsNotifySwitcherChecked(false);
  }, []);

  return (
    <>
      <Popover
        open={isSubscribeFormShown}
        flip
        preventOverflow
        placement="bottom"
        content={
          <SubscribeForm
            insideRefs={[switcherWrapperRef]}
            isOutsideActive={isSubscribeFormShown}
            isDisabled={isSubscribeFormDisabled}
            onOutsideClick={handleSubscribeFormClose}
            onSubmit={handleSubscribeFormSubmit}
          />
        }
      >
        <div className={s['popover-wrapper']}>
          <PostCommentsNotifySwitcher
            ref={switcherWrapperRef}
            isChecked={isNotifySwitcherChecked}
            isDisabled={isDisabled || isNotifySwitcherDisabled}
            onChange={(_, value) => handleNotifySwitcher(value)}
          />
        </div>
      </Popover>

      <CustomTooltip
        closeButton
        anchorRef={switcherWrapperRef}
        content={<SubscribeSucceedTooltipContent email={email} />}
        flip
        arrow
        maxWidth={450}
        open={isSubscribedTooltipShown}
        placement="bottom"
        portal
        preventOverflow
        size="S"
        onClose={() => setIsSubscribedTooltipShown(false)}
        theme="blue"
      />

      <CustomTooltip
        closeButton
        anchorRef={switcherWrapperRef}
        content={<UnsubscribeSucceedTooltipContent />}
        flip
        arrow
        maxWidth={450}
        open={isUnsubscribedTooltipShown}
        placement="bottom"
        portal
        preventOverflow
        size="S"
        onClose={() => setIsUnsubscribedTooltipShown(false)}
        theme="white"
      />
    </>
  );
};
