import { TThunkAction } from '../../../types/redux';
import { setPostAttribute } from '../setPostAttribute';
import {
  fetchCommentCheckUserSubscription,
  TCommentCheckUserSubscriptionResponse,
} from '../../../repositories/content-subscriptions/v1/comment-check-user-subscription';
import { throwError } from '../../../utils';

interface IGetIsPostCommentsUserSubscribed {
  id: number;
}

export const getIsPostCommentsUserSubscribed = ({
  id,
}: IGetIsPostCommentsUserSubscribed): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    try {
      const { response, statusCode }: TCommentCheckUserSubscriptionResponse = await fetchCommentCheckUserSubscription({
        httpApi,
        parameters: {
          journalId: id,
        },
      });

      if (statusCode !== 200) {
        throwError({
          message: 'Не удалось загрузить значение isSubscribed',
          statusCode,
          domain: 'actions.getIsPostCommentsUserSubscribed',
        });

        return;
      }

      dispatch(setPostAttribute({ key: 'isSubscribedToComments', value: response.isSubscribed }));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getIsPostCommentsUserSubscribed',
      });
    }
  };
};
