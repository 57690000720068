import React, { forwardRef } from 'react';

import * as s from './Control.css';

export interface IControl {
  children: React.ReactNode;
  isDisabled?: boolean;
  onClick?(e: React.MouseEvent): void;
}

/**
 * Кнопка переключения видимости формы комментария
 * !Важно сохранить текущую структуру вложенности:
 * <div ref={ref}>
 *   <div>
 *     Кнопка
 *   </div>
 * </div>
 */
export const Control = forwardRef<HTMLDivElement, IControl>(({ children, isDisabled, onClick }, ref) => {
  const isClickable = !isDisabled && onClick;

  return (
    <div ref={ref}>
      <div>
        <div
          className={`${s['button']} ${isClickable ? s['_clickable'] : ''} ${isDisabled ? s['_disabled'] : ''}`}
          onClick={e => isClickable && onClick(e)}
        >
          {children}
        </div>
      </div>
    </div>
  );
});

Control.displayName = 'Control';
