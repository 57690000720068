import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestAuthentication } from '../../actions/user';
import { TThunkDispatch } from '../../types/redux';
import { selectUser } from '../../selectors/user';

export function useRequestAuthentication() {
  const dispatch = useDispatch<TThunkDispatch>();
  const { isAuthenticated } = useSelector(selectUser);

  /**
   * Блокирует действие и открывает
   * модалку авторизации, если пользователь - гость
   */
  return useCallback(() => (isAuthenticated ? true : dispatch(requestAuthentication())), [dispatch, isAuthenticated]);
}
