import React from 'react';
import { useSelector } from 'react-redux';

import { PostLikesLayout } from '../../components/PostLikesLayout';
import { PostLikes } from '../../components/PostLikes';
import { PostTelegramLink } from '../../components/PostTelegramLink';
import { selectPost } from '../../selectors/post';
import { useLikes } from './utils';
import { ERequestStatus } from '../../types/requestStatus';
import { ELikeType } from '../../types/likes';

/**
 * Контейнер блока лайков для поста
 */
export const PostLikesContainer = () => {
  const post = useSelector(selectPost);
  const handleLikeClick = useLikes();

  if (ERequestStatus.Loading === post.status) {
    return <div>loading...</div>;
  }

  if (!('id' in post)) {
    return null;
  }

  return (
    <PostLikesLayout>
      <PostLikes
        onLikeClick={() => handleLikeClick({ postId: post.id, likeType: ELikeType.Like })}
        onDislikeClick={() => handleLikeClick({ postId: post.id, likeType: ELikeType.Dislike })}
      />

      <PostTelegramLink />
    </PostLikesLayout>
  );
};
