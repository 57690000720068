import React, { useState } from 'react';
import { Deslike16 as IconSocialDislikeOn16, Like16 as IconSocialLikeOn16, ModalWindow } from '@cian/ui-kit';

import { ILike, Like } from '../Like';
import { LikesModalTabs as ModalTabs } from '../LikesModalTabs';
import { LikesTooltipContent as TooltipContent } from '../LikesTooltipContent';
import { LikesModalTabsContent as ModalTabsContent } from '../LikesModalTabsContent';
import { ELikeType } from '../../../../types/likes';
import { TLikedUsersListState } from '../../../../types/commentList';
import * as s from './Likes.css';

export interface ILikesToggleLikedUsers {
  isShown: boolean;
  type: ELikeType;
}

export interface ILikes extends Pick<ILike, 'isDisabled'> {
  likesCount: ILike['count'];
  dislikesCount: ILike['count'];
  likedUsers?: TLikedUsersListState;
  dislikedUsers?: TLikedUsersListState;
  onClick?(type: ELikeType): void;
  onToggleLikedUsers?({ isShown, type }: ILikesToggleLikedUsers): void;
}

/**
 * Кнопки лайк/дизлайк
 */
export const Likes = ({
  likesCount,
  dislikesCount,
  likedUsers,
  dislikedUsers,
  isDisabled,
  onClick,
  onToggleLikedUsers,
}: ILikes) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [activeModalTab, setActiveModalTab] = useState(ELikeType.Like);

  const handleClickTooltip = (likeType: ELikeType) => {
    setActiveModalTab(likeType);
    setIsModalOpened(true);
  };

  return (
    <>
      <div className={s['wrapper']}>
        <Like
          count={likesCount}
          IconComponent={IconSocialLikeOn16}
          tooltipContent={<TooltipContent {...likedUsers} onClick={() => handleClickTooltip(ELikeType.Like)} />}
          isDisabled={isDisabled}
          onClick={onClick && (() => onClick(ELikeType.Like))}
          onToggleTooltip={
            onToggleLikedUsers && ((isShown: boolean) => onToggleLikedUsers({ isShown, type: ELikeType.Like }))
          }
        />

        <Like
          count={dislikesCount}
          IconComponent={IconSocialDislikeOn16}
          tooltipContent={<TooltipContent {...dislikedUsers} onClick={() => handleClickTooltip(ELikeType.Dislike)} />}
          isDisabled={isDisabled}
          onClick={onClick && (() => onClick(ELikeType.Dislike))}
          onToggleTooltip={
            onToggleLikedUsers && ((isShown: boolean) => onToggleLikedUsers({ isShown, type: ELikeType.Dislike }))
          }
        />
      </div>

      <ModalWindow
        open={isModalOpened}
        size={'XS'}
        fixed
        width={450}
        height={419}
        escape
        header={
          <ModalTabs
            likesCount={likesCount}
            dislikesCount={dislikesCount}
            onChange={likeType => setActiveModalTab(likeType)}
            activeTab={activeModalTab}
          />
        }
        onClose={() => setIsModalOpened(false)}
      >
        {isModalOpened && (
          <ModalTabsContent
            activeTab={activeModalTab}
            likedUsers={likedUsers}
            dislikedUsers={dislikedUsers}
            onTabShown={onToggleLikedUsers && (type => onToggleLikedUsers({ isShown: true, type }))}
          />
        )}
      </ModalWindow>
    </>
  );
};
