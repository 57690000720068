import { IHTTPCompletedResponse } from '@cian/http-api';

import { TThunkAction } from '../../../types/redux';
import {
  requestEditComment,
  IEditCommentRequest,
  TResponses,
  IEditCommentResponse,
} from '../../../services/postComments/editComment';
import { throwError } from '../../../utils';
import { editCommentSucceed } from './editCommentSucceed';

export const editComment = ({ id, comment }: Required<IEditCommentRequest>): TThunkAction<Promise<boolean>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger, config } = context;

    try {
      const { responseBody: response, responseStatusCode: statusCode }: IHTTPCompletedResponse<TResponses> =
        await requestEditComment({
          httpApi,
          bodyParameters: {
            comment,
          },
          queryParameters: {
            id,
          },
          config: {
            browserUrl: config.get<string>('backend-content.browserUrl') || '',
          },
        });

      if (statusCode !== 200) {
        throwError({ message: 'Не удалось отредактировать комментарий', statusCode, domain: 'actions.editComment' });

        return false;
      }

      dispatch(
        editCommentSucceed({
          id: Number(id),
          comment: (response as IEditCommentResponse)?.data?.attributes?.comment || comment,
        }),
      );

      return true;
    } catch (e) {
      logger.error(e, {
        domain: 'actions.editComment',
      });

      return false;
    }
  };
};
