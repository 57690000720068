import { TThunkAction } from '../../../types/redux';
import { getLikedUsersLoading } from './getLikedUsersLoading';
import { getLikedUsersSucceed } from './getLikedUsersSucceed';
import { getLikedUsersFailed } from './getLikedUsersFailed';
import { fetchGetLikeList, TGetLikeListResponse } from '../../../services/likes/getLikeList';
import { throwError } from '../../../utils';
import { ELikeType } from '../../../types/likes';

export interface IGetLikedUsersParams {
  commentId: number;
  likeType: ELikeType;
}

export const getLikedUsers = ({ commentId, likeType }: IGetLikedUsersParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getLikedUsersLoading({ commentId, likeType }));

    try {
      const { response, statusCode }: TGetLikeListResponse = await fetchGetLikeList({
        httpApi,
        parameters: {
          type: 'threadedcomment',
          object_pk: commentId,
          vote: likeType,
          page_size: 99999,
        },
      });

      if (statusCode !== 200) {
        throwError({
          message: 'Не удалось загрузить лайкнувших пользователей к комменту',
          statusCode,
          domain: 'actions.getLikedUsers',
        });

        return;
      }

      dispatch(getLikedUsersSucceed({ commentId, likeType, items: response.data.map(item => item.attributes.user) }));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getLikedUsers',
      });

      dispatch(getLikedUsersFailed({ commentId, likeType }));
    }
  };
};
