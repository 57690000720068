import * as React from 'react';
import { IIconProps } from '@cian/ui-kit/icons/internal/types';

export const IconFunctionalSuccessCircle24 = ({ color }: IIconProps) => {
  const currentColor = color ? `var(--${color})` : 'currentColor';

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4931_43196)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12C0 5.4 5.4 0 12 0C18.6 0 24 5.4 24 12C24 18.6 18.6 24 12 24C5.4 24 0 18.6 0 12ZM18.2071 9.20711L16.7929 7.79289L10.5 14.0858L7.20711 10.7929L5.79289 12.2071L10.5 16.9142L18.2071 9.20711Z"
          fill={currentColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4931_43196">
          <rect width="24" height="24" fill={currentColor} />
        </clipPath>
      </defs>
    </svg>
  );
};
