import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { prepareListingOrdering, preparePageNumber } from '../../utils';

type TDependencyName = 'page' | 'ordering';
type TParams = { [key in TDependencyName]?: number | string };

/**
 * Подготавливает qs-параметры
 */
export const useSearchParams = <Params extends TParams = TParams>(dependencyNames: TDependencyName[]): Params => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const getParams = useCallback(
    () =>
      dependencyNames.reduce((acc, dependencyName) => {
        if (dependencyName === 'page') {
          const value = preparePageNumber(searchParams.get('page') || '');

          return { ...acc, ...(value ? { page: value } : {}) };
        }

        if (dependencyName === 'ordering') {
          const value = prepareListingOrdering(searchParams.get('ordering') || '');

          return { ...acc, ...(value ? { ordering: value } : {}) };
        }

        return acc;
      }, {} as Params),
    [dependencyNames, searchParams],
  );

  const [params, setParams] = useState<Params>(getParams());

  useEffect(() => {
    const paramsNew = getParams();

    if (JSON.stringify(paramsNew) === JSON.stringify(params)) {
      return;
    }

    setParams(paramsNew);
  }, [getParams, params]);

  return params;
};
