import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { useTimeout } from '../useTimeout';
import { useIsMounted } from '../useIsMounted';
import { useEffectOnce } from '../useEffectOnce';

interface IUseCollapseOptions {
  isDefaultOpened?: boolean;
  transitionDurationMs?: number;
}

/**
 * Создает эффект коллапса путем переключения css-свойства max-height
 * ref-компонент по структуре должен иметь дочерний элемент
 * ------------------------------------------------------------------
 * Пример использования:
 * <div ref={ref}>
 *   <div>...</div>
 * </div>
 */
export function useCollapse<T extends HTMLElement | void = void>(
  parentRef: RefObject<T>,
  { isDefaultOpened = false, transitionDurationMs = 300 }: IUseCollapseOptions = {},
) {
  const [isOpened, setIsOpened] = useState(isDefaultOpened);
  const [isOpen, setIsOpen] = useState(isDefaultOpened);
  const [isOpenPrev, setIsOpenPrev] = useState(isDefaultOpened);
  const isMounted = useIsMounted();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  /**
   * Устанавливает стили и запускает анимацию
   */
  const setStyles = useCallback(
    ({
      overflowY = 'hidden',
      maxHeight = '10000px',
      transitionProperty = 'max-height',
      transitionTimingFunction = 'ease-in-out',
      transitionDuration = `${transitionDurationMs}ms`,
    }: Record<string, string>) => {
      if (!parentRef?.current) {
        return;
      }

      const { style } = parentRef.current;

      style.overflowY = overflowY;
      style.maxHeight = maxHeight;
      style.transitionProperty = transitionProperty;
      style.transitionTimingFunction = transitionTimingFunction;
      style.transitionDuration = transitionDuration;
    },
    [parentRef, transitionDurationMs],
  );

  /**
   * Рассчитывает высоту и запускает установку стилей
   */
  const calcAndSetStyles = useCallback(() => {
    if (!parentRef?.current) {
      return;
    }

    const contentWrapper = parentRef.current?.childNodes?.[0] as HTMLElement;

    if (!contentWrapper) {
      return;
    }

    setStyles({ maxHeight: isOpen ? `${contentWrapper.getBoundingClientRect().height}px` : `0` });
  }, [isOpen, parentRef, setStyles]);

  /**
   * Сброс стилей
   */
  const resetStyles = useCallback(() => {
    setStyles({
      overflowY: '',
      maxHeight: '',
      transitionProperty: '',
      transitionTimingFunction: '',
      transitionDuration: '',
    });
  }, [setStyles]);

  /**
   * Инициализация
   */
  useEffectOnce(() => {
    if (!parentRef?.current || isDefaultOpened) {
      return;
    }

    // Накладываем стили только для изначально схлопнутого состояния
    calcAndSetStyles();

    return () => {
      resetStyles();
    };
  });

  /**
   * Запускает расчет стилей и анимацию по ключу isOpen.
   * Сохраняет предыдущее состояние
   */
  useEffect(() => {
    if (!parentRef?.current || isOpen === isOpenPrev) {
      return;
    }

    calcAndSetStyles();
    setIsOpenPrev(isOpen);
  }, [calcAndSetStyles, isOpen, isOpenPrev, parentRef]);

  /**
   * После смены состояния и отработки анимации
   * удаляет стили (только для раскрытого состояния)
   */
  useTimeout(
    () => {
      if (!isMounted || !isOpen) {
        return;
      }

      resetStyles();
    },
    isOpen ? transitionDurationMs : 0,
  );

  /**
   * Переключает состояние коллапсера.
   * Промис отрабатывает по завершению анимации
   */
  const collapse = useCallback(
    (value?: boolean): Promise<boolean> => {
      const newValue = value !== undefined ? value : !isOpen;
      // Перед запуском анимации проставляем стили текущего состояния
      calcAndSetStyles();
      setIsOpen(newValue);

      return new Promise(resolve => {
        clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
          if (!isMounted()) {
            clearTimeout(timeoutRef.current);
            resolve(false);

            return;
          }

          setIsOpened(newValue);
          resolve(true);
        }, transitionDurationMs);
      });
    },
    [calcAndSetStyles, isMounted, isOpen, transitionDurationMs],
  );

  return [collapse, isOpened] as const;
}
