import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  subscribeToComments,
  unsubscribeFromComments,
  ISubscribeToComments,
  IUnsubscribeFromComments,
} from '../../../../actions/postComments';
import { TThunkDispatch } from '../../../../types/redux';
import { useRequestAuthentication } from '../../../../utils';

export function useCommentsSubscription() {
  const dispatch = useDispatch<TThunkDispatch>();
  const checkAuth = useRequestAuthentication();

  /**
   * Осуществляет подписку на обновления комментариев поста
   */
  const handleSubscribeToComments = useCallback(
    async (params: ISubscribeToComments) => dispatch(subscribeToComments(params)),
    [dispatch],
  );

  /**
   * Осуществляет отписку от обновления комментариев поста
   */
  const handleUnsubscribeFromComments = useCallback(
    async (params: IUnsubscribeFromComments) => checkAuth() && dispatch(unsubscribeFromComments(params)),
    [checkAuth, dispatch],
  );

  return { handleSubscribeToComments, handleUnsubscribeFromComments };
}
