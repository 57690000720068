import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PostCommentsHeader } from '../../components/PostCommentsHeader';
import { selectComments } from '../../selectors/postCard';
import { selectUser } from '../../selectors/user';
import { selectPost } from '../../selectors/post';
import { prepareCommentsOrdering } from '../../utils';
import { useCommentsSubscription } from './utils';
import { EOrdering } from '../../repositories/journal/v1/get-comments';
import { ERequestStatus } from '../../types/requestStatus';
import { IAuthenticatedUser, TUserState } from '../../types/user';

/**
 * Контейнер шапки листинга комментариев
 */
export const PostCommentsHeaderContainer = () => {
  const { search } = useLocation();
  const user = useSelector(selectUser);
  const post = useSelector(selectPost);
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const [ordering, setOrdering] = useState<EOrdering>(EOrdering.Likes_count);
  const {
    status: commentsStatus,
    meta: { total: commentsCount },
  } = useSelector(selectComments);
  const { handleSubscribeToComments, handleUnsubscribeFromComments } = useCommentsSubscription();

  /**
   * Сохраняет состояние сортировки в стэйт
   */
  useEffect(() => {
    const qsOrdering = searchParams.get('ordering');
    const qsOrderingPrepared = qsOrdering ? prepareCommentsOrdering(qsOrdering) : '';

    if (!qsOrderingPrepared || ordering === qsOrderingPrepared) {
      return;
    }

    setOrdering(qsOrderingPrepared);
  }, [ordering, searchParams]);

  /**
   * Устанавливает сортировку
   */
  const handleSorting = useCallback(() => {
    const newOrdering = ordering === EOrdering.Likes_count ? EOrdering.Published_at : EOrdering.Likes_count;

    searchParams.set('ordering', newOrdering);
    searchParams.delete('page');
    history.push(`?${searchParams.toString()}`);
  }, [history, ordering, searchParams]);

  /**
   * Задисэйблены ли контролы
   */
  const isDisabled = useCallback(() => {
    return [ERequestStatus.Loading, ERequestStatus.Failed].includes(commentsStatus);
  }, [commentsStatus]);

  if (!('id' in post)) {
    return null;
  }

  const {
    id: postId,
    attributes: { type: postType, isSubscribedToComments },
  } = post;

  const { email: userEmail } = user as TUserState & IAuthenticatedUser;

  return (
    <PostCommentsHeader
      commentsCount={commentsCount}
      isUserAuthenticated={user.isAuthenticated}
      isSubscriptionChecked={Boolean(isSubscribedToComments)}
      userEmail={(user.isAuthenticated && userEmail) || ''}
      onSubscribeToComments={({ email, isChecked }) =>
        handleSubscribeToComments({
          postId,
          postType,
          email: email || userEmail || '',
          isSubscriptionToNewsChecked: Boolean(isChecked),
        })
      }
      onUnsubscribeFromComments={() => handleUnsubscribeFromComments({ postId, postType, email: userEmail || '' })}
      ordering={ordering}
      // TODO: сделать склейку комментов-дублей и включить сортировку
      isDisabled={isDisabled()}
      onSort={undefined && handleSorting}
    />
  );
};
