import React from 'react';
import { LinkButton, UIHeading4, UIText2 } from '@cian/ui-kit';

import { Telegram40 } from '../Icons';
import { CIAN_OFFICIAL_TELEGRAM_LINK } from '../../constants';
import * as s from './PostTelegramLink.css';

/**
 * Блок-ссылка на Телеграм-канал
 */
export const PostTelegramLink = () => (
  <div className={s['wrapper']}>
    <div className={s['content-wrapper']}>
      <Telegram40 />

      <div>
        <UIHeading4>Наш канал в Telegram</UIHeading4>
        <UIText2>@cian_official</UIText2>
      </div>
    </div>

    <LinkButton theme={'fill_primary'} fullWidth href={CIAN_OFFICIAL_TELEGRAM_LINK} target={'_blank'}>
      Подписаться
    </LinkButton>
  </div>
);
