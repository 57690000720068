import { ERequestStatus } from '../../../types/requestStatus';
import { ICommentParent, TCommentListState } from '../../../types/commentList';
import { LikeTypeLikedUsers, POST_CARD_COMMENTS_REQUEST_LIMIT } from '../../../constants';
import {
  EAddCommentActionType,
  EAddLikeActionType,
  EEditCommentActionType,
  EGetLikedUsersActionType,
  EGetPostCommentsActionType,
  EHideCommentActionType,
  ERemoveCommentActionType,
  EResetPostCommentsActionType,
  ESetCommentParamActionType,
  TPostCommentsActions,
} from '../../../actions/postComments';
import { spliceCommentData } from '../utils/spliceCommentData';

export const defaultState: TCommentListState = {
  status: ERequestStatus.Initial,
  meta: {
    limit: POST_CARD_COMMENTS_REQUEST_LIMIT,
    offset: 0,
    total: 0,
    totalParent: 0,
  },
  items: [],
};

export const commentsReducer = (state = defaultState, action: TPostCommentsActions) => {
  switch (action.type) {
    case EGetPostCommentsActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetPostCommentsActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case EGetPostCommentsActionType.AppendSucceed:
      return {
        ...state,
        meta: action.payload.meta,
        items: [...state.items, ...action.payload.items],
        status: ERequestStatus.Succeed,
      };

    case EGetPostCommentsActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetPostCommentsActionType.Reset:
      return defaultState;

    case EAddCommentActionType.AppendSucceed: {
      const { comment, parentCommentId } = action.payload;

      /** Комментарий к статье */
      if (!parentCommentId) {
        return {
          ...state,
          items: [comment, ...state.items] as ICommentParent[],
        };
      }

      /** Комментарий к комментарию */
      return {
        ...state,
        items: spliceCommentData({
          comments: state.items,
          id: parentCommentId,
          data: (parentItem: ICommentParent) => ({
            ...parentItem,
            comments: [comment, ...(parentItem?.comments || [])],
          }),
        }),
      };
    }

    case EAddLikeActionType.Succeed: {
      const { likesCount, dislikesCount, commentId } = action.payload;

      return {
        ...state,
        items: spliceCommentData({ comments: state.items, id: commentId, data: { likesCount, dislikesCount } }),
      };
    }

    case EEditCommentActionType.Succeed: {
      const { comment, id } = action.payload;

      return {
        ...state,
        items: spliceCommentData({ comments: state.items, id, data: { comment } }),
      };
    }

    case ERemoveCommentActionType.Succeed: {
      return {
        ...state,
        items: spliceCommentData({ comments: state.items, id: action.payload }),
      };
    }

    case EHideCommentActionType.Succeed: {
      return {
        ...state,
        items: spliceCommentData({ comments: state.items, id: action.payload }),
      };
    }

    case ESetCommentParamActionType.Set: {
      const { id, key, value } = action.payload;

      return {
        ...state,
        items: spliceCommentData({ comments: state.items, id, data: { [key]: value } }),
      };
    }

    case EGetLikedUsersActionType.Loading: {
      const { commentId, likeType } = action.payload;

      return {
        ...state,
        items: spliceCommentData({
          comments: state.items,
          id: commentId,
          data: comment => ({
            ...comment,
            [LikeTypeLikedUsers[likeType]]: {
              items: [],
              ...comment?.[LikeTypeLikedUsers[likeType]],
              status: ERequestStatus.Loading,
            },
          }),
        }),
      };
    }

    case EGetLikedUsersActionType.Succeed: {
      const { commentId, likeType, items } = action.payload;

      return {
        ...state,
        items: spliceCommentData({
          comments: state.items,
          id: commentId,
          data: comment => ({
            ...comment,
            [LikeTypeLikedUsers[likeType]]: {
              ...comment?.[LikeTypeLikedUsers[likeType]],
              items,
              status: ERequestStatus.Succeed,
            },
          }),
        }),
      };
    }

    case EGetLikedUsersActionType.Failed: {
      const { commentId, likeType } = action.payload;

      return {
        ...state,
        items: spliceCommentData({
          comments: state.items,
          id: commentId,
          data: comment => ({
            ...comment,
            [LikeTypeLikedUsers[likeType]]: {
              items: [],
              ...comment?.[LikeTypeLikedUsers[likeType]],
              status: ERequestStatus.Failed,
            },
          }),
        }),
      };
    }

    default:
      return state;
  }
};
