import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { editComment } from '../../../../actions/postComments';
import { TThunkDispatch } from '../../../../types/redux';
import { useRequestAuthentication } from '../../../../utils';

interface IEdit {
  id: number;
  value: string;
}

export function useEditComment() {
  const dispatch = useDispatch<TThunkDispatch>();
  const checkAuth = useRequestAuthentication();

  /**
   * Обновляет коммент или отправляет на авторизацию
   */
  return useCallback(
    async ({ id, value }: IEdit) => checkAuth() && dispatch(editComment({ id, comment: value })),
    [checkAuth, dispatch],
  );
}
