import { TUserState } from '../../types/user';
import { IUserDataItemSchema } from '../../repositories/journal/entities/user_data_item/UserDataItemSchema';

/** Создает пользовательскую структуру authorObject */
export const transformUserToAuthorObject = (user: TUserState): IUserDataItemSchema => {
  if (!user.isAuthenticated) {
    return {};
  }

  const { avatarUrl, email, displayName, isAgent, isEmailConfirmed, isProfi, permissions, userId } = user;

  return {
    avatar: avatarUrl,
    companyName: null,
    email,
    fullName: displayName,
    isAgent,
    isEmailConfirmed,
    isProfi,
    linkUserProfiDescription: null,
    linkUserProfile: null,
    linkUserTrustDescription: null,
    locationIds: null,
    permissions,
    specialistLink: null,
    userId,
    userIdentifications: null,
    userTrust: null,
  };
};
