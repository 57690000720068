/* eslint-disable */
import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';

import { TSubscribeToCommentsModel, ISubscribeToCommentsRequest, TSubscribeToCommentsResponse } from './types';

const defaultConfig: TSubscribeToCommentsModel = {
  apiType: 'public',
  requestType: 'formData',
  assertStatusCodes: [201, 400],
  method: 'POST',
  microserviceName: 'backend-content',
  pathApi: '/v2/subscribe-comment',
  hostType: 'api',
};

function createSubscribeToCommentsModel(parameters: ISubscribeToCommentsRequest): TSubscribeToCommentsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISubscribeToCommentsOptions<TResponse201, TResponse400> {
  httpApi: IHttpApi;
  parameters: ISubscribeToCommentsRequest;
  config?: IHttpApiFetchConfig;
}

async function fetchSubscribeToComments<TResponse201, TResponse400>({
  httpApi,
  parameters,
  config,
}: ISubscribeToCommentsOptions<TResponse201, TResponse400>): Promise<
  TResponse201 | TResponse400 | TSubscribeToCommentsResponse
> {
  return await httpApi.fetch(createSubscribeToCommentsModel(parameters), {
    ...config,
    requestConfig: { withCredentials: true },
  });
}

export { defaultConfig, createSubscribeToCommentsModel, fetchSubscribeToComments };
