/* eslint-disable */
import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';

import {
  TGetLikeListModel,
  IGetLikeListRequest,
  TGetLikeListResponse,
} from './types';

const defaultConfig: TGetLikeListModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'backend-content',
  pathApi: '/v1/get-like-list',
  hostType: 'api',
};

function createGetLikeListModel(parameters: IGetLikeListRequest): TGetLikeListModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetLikeListOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetLikeListRequest;
  config?: IHttpApiFetchConfig;
}

async function fetchGetLikeList<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
}: IGetLikeListOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetLikeListResponse
  > {
  return await httpApi.fetch(createGetLikeListModel(parameters), { ...config, requestConfig: { withCredentials: true } });
}

export { defaultConfig, createGetLikeListModel, fetchGetLikeList };
