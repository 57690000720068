import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Tabs } from './components/Tabs';
import { Counts } from './components/Counts';
import { TabsContent } from './components/TabsContent';
import { PostTabsLayout } from '../../components/PostTabsLayout';
import { selectPost } from '../../selectors/post';
import { ERequestStatus } from '../../types/requestStatus';

export enum ETabs {
  Authors = 'authors',
  Tags = 'tags',
}

/**
 * Контейнер табов с авторами и тегами для поста
 */
export const PostTabsContainer = () => {
  const post = useSelector(selectPost);
  const [activeTab, setActiveTab] = useState(ETabs.Authors);

  if (ERequestStatus.Loading === post.status) {
    return <div>loading...</div>;
  }

  if (!('id' in post)) {
    return null;
  }

  const {
    attributes: { likesCount, dislikesCount, commentsCount, authorsLink, tags },
  } = post;

  if (!authorsLink?.length && !tags?.length) {
    return null;
  }

  return (
    <PostTabsLayout>
      <Tabs
        activeTab={activeTab}
        hasAuthors={Boolean(authorsLink?.length)}
        hasTags={Boolean(tags?.length)}
        onChange={setActiveTab}
      >
        <Counts
          likesCount={likesCount ?? undefined}
          dislikesCount={dislikesCount ?? undefined}
          commentsCount={commentsCount ?? undefined}
        />
      </Tabs>

      <TabsContent activeTab={activeTab} authors={authorsLink ?? undefined} tags={tags ?? undefined} />
    </PostTabsLayout>
  );
};
