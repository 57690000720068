import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ERequestStatus } from '../../types/requestStatus';
import { correctPathname } from '../correctPathname';
import { preparePageNumber } from '../preparePageNumber';
import { useCurrentListing } from '../useCurrentListing';
import { prepareQSParam } from '../prepareQSParam';

type TQSParams = 'page' | 'with-tag';

/**
 * В случае положительного ответа ручки с пустыми данными
 * при наличии qs-фильтров, убираем qs-фильтры,
 * чтобы попробовать получить данные снова
 */
export const useRemoveWrongSearchParams = (qsParams: TQSParams[] = ['page']) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { items, status } = useCurrentListing();

  const getSomeCondition = useCallback(
    () =>
      qsParams.some(key => {
        switch (key) {
          case 'page': {
            return preparePageNumber(searchParams.get('page') || '') > 1;
          }

          case 'with-tag': {
            return prepareQSParam(searchParams.get('with-tag'));
          }

          default:
            return false;
        }
      }),
    [qsParams, searchParams],
  );

  useEffect(() => {
    if (status === ERequestStatus.Succeed && !items.length && getSomeCondition()) {
      history.replace(`${correctPathname(pathname)}`);
    }
  }, [items.length, history, status, pathname, getSomeCondition]);
};
