import { TThunkAction } from '../../../types/redux';
import {
  fetchUnsubscribeFromComments,
  TUnsubscribeFromCommentsResponse,
  IUnsubscribeFromCommentsRequest,
} from '../../../services/postComments/unsubscribeFromComments';
import { throwError } from '../../../utils';
import { PostTypeAsContentTypeModel } from '../../../constants';
import { EType } from '../../../repositories/journal/entities/journal/JournalAttributesSchema';
import { setPostAttribute } from '../../post';

export interface IUnsubscribeFromComments {
  postType: EType;
  email: string;
  postId: IUnsubscribeFromCommentsRequest['object_pk'];
}

export const unsubscribeFromComments = ({
  postType,
  email,
  postId,
}: IUnsubscribeFromComments): TThunkAction<Promise<boolean>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const { userNew: user } = getState();

    try {
      const { statusCode }: TUnsubscribeFromCommentsResponse = await fetchUnsubscribeFromComments({
        httpApi,
        parameters: {
          user_id: (user.isAuthenticated && user.userId) || undefined,
          email,
          content_type_model: PostTypeAsContentTypeModel[postType],
          object_pk: postId,
        },
      });

      if (statusCode !== 200) {
        throwError({
          message: 'Не удалось отписаться от комментов',
          statusCode,
          domain: 'actions.unsubscribeFromComments',
        });

        return false;
      }

      dispatch(setPostAttribute({ key: 'isSubscribedToComments', value: false }));

      return true;
    } catch (e) {
      logger.error(e, {
        domain: 'actions.unsubscribeFromComments',
      });

      return false;
    }
  };
};
