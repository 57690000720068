import { TThunkAction } from '../../../types/redux';

import { fetchAddComment, TAddCommentResponse, IAddCommentRequest } from '../../../services/postComments/addComment';
import { throwError } from '../../../utils';
import { addCommentAppendSucceed } from './addCommentAppendSucceed';
import { createPostComment } from '../../../utils/createPostComment';
import { transformUserToAuthorObject } from '../../../utils/transformUserToAuthorObject';
import { PostTypeAsContentTypeModel } from '../../../constants';
import { EType } from '../../../repositories/journal/entities/journal/JournalAttributesSchema';

interface IAddComment {
  postType: EType;
  isSubscriptionChecked: IAddCommentRequest['is_subscribed_to_comments'];
  postId: IAddCommentRequest['object_pk'];
  parentCommentId: IAddCommentRequest['parent_id'];
  comment: IAddCommentRequest['comment'];
}

export const addComment = ({
  postType,
  isSubscriptionChecked,
  postId,
  parentCommentId,
  comment,
}: IAddComment): TThunkAction<Promise<boolean>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const { userNew } = getState();

    try {
      const { response, statusCode }: TAddCommentResponse = await fetchAddComment({
        httpApi,
        parameters: {
          content_type_model: PostTypeAsContentTypeModel[postType],
          is_subscribed_to_comments: isSubscriptionChecked,
          object_pk: postId,
          ...(parentCommentId ? { parent_id: parentCommentId } : {}),
          comment,
        },
      });

      if (statusCode !== 201) {
        throwError({ message: 'Не удалось добавить комментарий', statusCode, domain: 'actions.addComment' });

        return false;
      }

      const {
        id,
        attributes: {
          comment: commentFromResponse,
          datePublish: datePublishedFromResponse,
          bestAdvice: bestAdviceFromResponse,
          parentId: parentCommentIdFromResponse,
        },
      } = response.data;

      dispatch(
        addCommentAppendSucceed({
          comment: createPostComment({
            bestAdvice: bestAdviceFromResponse || false,
            comment: commentFromResponse || '',
            datePublish: datePublishedFromResponse || '',
            id: Number(id),
            userDataItem: transformUserToAuthorObject(userNew),
            parentCommentId: Number(parentCommentIdFromResponse),
            isJustAdded: true,
          }),
          parentCommentId,
        }),
      );

      return true;
    } catch (e) {
      logger.error(e, {
        domain: 'actions.addComment',
      });

      return false;
    }
  };
};
