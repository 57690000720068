import React from 'react';

import { IconFunctionalSuccessCircle24 } from '../../../Icons';
import * as s from './UnsubscribeSucceedTooltipContent.css';

/**
 * Содержимое тултипа завершения отписки
 */
export const UnsubscribeSucceedTooltipContent = () => {
  return (
    <div className={s['wrapper']}>
      <IconFunctionalSuccessCircle24 />
      Вы отписались от уведомлений
    </div>
  );
};
