import React from 'react';
import { UIHeading2 } from '@cian/ui-kit';
import { plural } from '@cian/utils';

import { numberWithDelimiter } from '../../../../utils';

interface IHeading {
  count: number;
}

/**
 * Заголовок комментариев
 */
export const Heading = ({ count }: IHeading) => {
  return (
    <UIHeading2>
      {numberWithDelimiter(count)} {plural(count, ['комментарий', 'комментария', 'комментариев'])}
    </UIHeading2>
  );
};
