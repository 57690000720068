interface IOptions {
  results?: number[];
  itemsName?: string;
}

/**
 * Рекурсивно ищет до первого совпадения и возвращает из коллекции индексный адрес для элемента с заданным id
 * Поддерживает как плоскую структуру "[{ id: 1 }, ..., { id: 20 }]",
 * так и вложенную "[{ id: 1 }, { id: 2, items: [{ id: 3 }, { id: 4, items: [{ id: 5 }] }] }]"
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findIndexesByIdRecursive = <T extends { [key: string]: any }>(
  items: T[],
  id: number,
  options: IOptions = {},
): number[] => {
  const { results = [], itemsName = 'items' } = options;

  items.some((item, index) => {
    if (item.id === id) {
      results.push(index);

      return true;
    }

    if (item?.[itemsName]?.length) {
      const array = findIndexesByIdRecursive(item[itemsName], id, options);

      if (array.length) {
        results.push(index, ...array);
      }
    }

    return false;
  });

  return results;
};
