import { EStatus } from '../../repositories/journal/entities/comments/CommentParentSchema';
import { IUserDataItemSchema } from '../../repositories/journal/entities/user_data_item/UserDataItemSchema';
import { ICommentParent, IComment } from '../../types/commentList';

interface ICreatePostCommentParams {
  bestAdvice: boolean;
  comment: string;
  datePublish: string;
  id: number;
  userDataItem: IUserDataItemSchema;
  parentCommentId: number;
  isJustAdded?: ICommentParent['isJustAdded'];
}

/** Создает структуру коммента офера */
export const createPostComment = ({
  bestAdvice,
  comment,
  datePublish,
  id,
  userDataItem,
  parentCommentId,
  isJustAdded,
}: ICreatePostCommentParams): ICommentParent | IComment => ({
  authorObject: userDataItem,
  bestAdvice,
  comment,
  ...(parentCommentId ? { comments: null } : {}),
  datePublish,
  dislikesCount: 0,
  id,
  likesCount: 0,
  status: EStatus.Unchecked,
  userId: Number(userDataItem.userId),
  ...(isJustAdded ? { isJustAdded: true } : {}),
});
