import { ITypedReduxAction } from '../../../types/redux/actionType';
import { ICommentParent, IComment } from '../../../types/commentList';

export enum ESetCommentParamActionType {
  Set = 'postComments/setCommentParam/set',
}

export type TComment = ICommentParent | IComment;

export interface ISetCommentParam<P extends keyof TComment = keyof TComment> {
  id: TComment['id'];
  key: P;
  value?: TComment[P];
}

export type TSetCommentParam = ITypedReduxAction<ESetCommentParamActionType.Set, ISetCommentParam>;
