import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, useDeviceType } from '@cian/ui-kit';

import { MainNewsContainer } from '../MainNews';
import { MainArticleContainer } from '../MainArticle';
import { selectInitialPageNumber } from '../../selectors/settings/selectInitialPageNumber';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import {
  ApplicationContext,
  buildPostUrl,
  preparePageNumber,
  useGetListing,
  useRemoveWrongSearchParams,
  useTimeoutEffect,
  useValidTag,
} from '../../utils';
import { selectMainArticles } from '../../selectors/mainArticles';
import { NewsSubscribeFormContainer } from '../NewsSubscribeForm';
import { CardListingItem, SkeletonCardListingItem } from '../../components/ListingItem';
import { PaginationContainer } from '../Pagination';
import { getMainArticles, resetMainArticles } from '../../actions/mainArticles';
import { MAIN_ARTICLES_REQUEST_LIMIT } from '../../constants';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import { MainPageSliderContainer } from '../MainPageSlider';
import { EType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';
import { selectSlider } from '../../selectors/sliders';
import { MainPageListingHeaderContainer } from '../ListingHeader';
import { ERequestStatus } from '../../types/requestStatus';
import { selectCurrentPageMeta } from '../../selectors/pagesMeta';
import { NothingFound } from '../../components/NotFound';
import { MainArticleAndMainNewsLayout } from '../../components/MainArticleAndMainNewsLayout';
import { unescapeHtml } from '../../../app/helpers/make_html_helper';
import { prepareSubtitle } from './helpers';
import * as s from './Listings.css';
import { AdfoxBannerMainPageArticle } from '../../components/AdfoxBanner';

export const MainPage = () => {
  const initialPageNumber = useSelector(selectInitialPageNumber);
  const { search, pathname } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const qsPage = preparePageNumber(searchParams.get('page') as string);
  const withTag = useValidTag('with-tag');
  const dispatch = useDispatch<TThunkDispatch>();
  const history = useHistory();
  const deviceType = useDeviceType();
  const isPhone = () => deviceType === 'phone';
  const { status: listingStatus, items: articles } = useSelector(selectMainArticles);
  const appendType = useSelector(selectListingAppendType);
  const {
    custom: { mainPageSliderName = '' },
  } = useContext(ApplicationContext);
  const { items: mainPageSliderItems } = useSelector((state: IApplicationState) =>
    selectSlider(state, mainPageSliderName),
  );
  const [isSkeletonShown, setIsSkeletonShown] = useState(
    listingStatus === ERequestStatus.Loading && appendType === 'set',
  );
  const { pathname: currentPageTypePathname } = useSelector((state: IApplicationState) =>
    selectCurrentPageMeta(state, pathname),
  );

  useRemoveWrongSearchParams(['page', 'with-tag']);

  /**
   * Запрашивает статьи на Главной/сохраняет в стор
   * Реагирует на qs-фильтры page и with-tag
   * */
  useGetListing(
    () => {
      dispatch(
        getMainArticles({
          tag: withTag,
          offset: (qsPage - 1) * MAIN_ARTICLES_REQUEST_LIMIT,
          limit: MAIN_ARTICLES_REQUEST_LIMIT,
          setType: appendType,
        }),
      ).finally();
    },
    { dependencyNames: ['page', 'with-tag', 'deviceType'] },
  );

  /** При выходе сбрасывает стор статей */
  useEffect(() => {
    return () => {
      dispatch(resetMainArticles());
    };
  }, [dispatch]);

  /** Создает небольшую задержку перед скрытием скелетона, когда элементов не найдено */
  useTimeoutEffect(() => {
    setIsSkeletonShown(listingStatus === ERequestStatus.Loading && appendType === 'set');
  }, [!isSkeletonShown || articles.length ? 0 : 500]);

  /**
   * При клике по плашке статьи роутит на соответствующую страницу статьи
   * При клике по шильдику рубрики в плашке статьи роутит на соответствующую рубрику
   */
  const handleClick = useCallback(
    (e, url: string) => {
      e.preventDefault();
      history.push(url);
    },
    [history],
  );

  /**
   * Шаблон для первой страницы Главной показывается в случаях:
   * - если текущая страница - первая;
   * - если текущая страница была подгружена к первой ("Показать больше");
   * Проверено! Не вызывает багов при хождении, в том числе, по истории
   */
  const isFirstPageTemplate = useCallback(
    () => qsPage === 1 || (qsPage !== 1 && initialPageNumber === 1),
    [initialPageNumber, qsPage],
  );

  return (
    <>
      <MainPageListingHeaderContainer />

      {/* Показываем только тогда, когда приложение стартовало с первой страницы */}
      {isFirstPageTemplate() && (
        <MainArticleAndMainNewsLayout articleNode={<MainArticleContainer />} newsNode={<MainNewsContainer />} />
      )}

      <div className={s['cards-wrapper']} data-web-ui-test-id="MainPageListing">
        {isSkeletonShown &&
          [...Array(6)].map((_, index) => (
            <Col key={index} xs={12} m={6} l={4}>
              <SkeletonCardListingItem />
            </Col>
          ))}

        {((listingStatus === ERequestStatus.Succeed && appendType === 'set') || appendType === 'append') &&
          (isFirstPageTemplate() ? articles.slice(1) : articles).map(
            (
              {
                id,
                attributes: {
                  image,
                  title,
                  subtitle,
                  datePublish,
                  commentsCount,
                  rubrics,
                  slug,
                  noIndex,
                  type,
                  likesCount,
                },
              },
              index,
            ) => (
              <Fragment key={`list-item-${index}`}>
                {/* Баннер и слайдер только для первой страницы */}
                {isFirstPageTemplate() && index === 5 && (
                  <>
                    <Col xs={12} m={6} l={4}>
                      <AdfoxBannerMainPageArticle />
                    </Col>

                    <div className={`${s['insert-wrapper']} ${mainPageSliderItems.length ? s['_active'] : ''}`}>
                      <MainPageSliderContainer />
                    </div>
                  </>
                )}

                {/* Форма подписки только для первой страницы */}
                {isFirstPageTemplate() && index === 11 && (
                  <div className={`${s['insert-wrapper']} ${s['_active']}`}>
                    <NewsSubscribeFormContainer />
                  </div>
                )}

                {/* Статья */}
                <Col xs={12} m={6} l={4}>
                  <CardListingItem
                    title={unescapeHtml(title)}
                    subtitle={prepareSubtitle(subtitle, type, Boolean(image))}
                    height={isPhone() ? undefined : 400}
                    image={image}
                    rubric={rubrics?.length ? rubrics[0] : undefined}
                    datePublish={datePublish}
                    numComments={commentsCount}
                    numLikes={likesCount}
                    url={buildPostUrl({ type: EType.Articles, slug, id })}
                    noIndex={Boolean(noIndex)}
                    type={type}
                    onRubricButtonClick={handleClick}
                    onClick={e => handleClick(e, buildPostUrl({ type: EType.Articles, slug, id }))}
                  />
                </Col>

                {/* Форма подписки, если приложение инициализировано НЕ на первой странице */}
                {!isFirstPageTemplate() && index === 5 && (
                  <div className={`${s['insert-wrapper']} ${s['_active']}`}>
                    <NewsSubscribeFormContainer />
                  </div>
                )}
              </Fragment>
            ),
          )}

        {[ERequestStatus.Succeed, ERequestStatus.Failed].includes(listingStatus) && !articles.length && (
          <NothingFound pathname={currentPageTypePathname} showResetFiltersLink={qsPage > 1 || Boolean(withTag)} />
        )}
      </div>

      {listingStatus === ERequestStatus.Succeed && (
        <div className={s['controls-wrapper']}>
          <PaginationContainer />
        </div>
      )}
    </>
  );
};
