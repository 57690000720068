import React from 'react';
import { Like16 as IconSocialLikeOn16, Dislike16 as IconSocialDislikeOn16 } from '@cian/ui-kit';

import { IconWithCount } from '../../../IconWithText';
import { ELikeType } from '../../../../types/likes';
import * as s from './LikesModalTabs.css';

export interface ILikesModalTabs {
  likesCount: number;
  dislikesCount: number;
  activeTab: ELikeType;
  onChange(likeType: ELikeType): void;
}

export const LikesModalTabs = ({ likesCount, dislikesCount, activeTab, onChange }: ILikesModalTabs) => {
  return (
    <div className={s['wrapper']}>
      <div
        className={`${s['tab']} ${activeTab === ELikeType.Like ? s['_active'] : ''}`}
        onClick={() => onChange(ELikeType.Like)}
      >
        <IconWithCount
          count={likesCount}
          icon={
            <div className={s['icon-wrapper']}>
              <IconSocialLikeOn16 />
            </div>
          }
        />
      </div>

      <div
        className={`${s['tab']} ${activeTab === ELikeType.Dislike ? s['_active'] : ''}`}
        onClick={() => onChange(ELikeType.Dislike)}
      >
        <IconWithCount
          count={dislikesCount}
          icon={
            <div className={s['icon-wrapper']}>
              <IconSocialDislikeOn16 />
            </div>
          }
        />
      </div>
    </div>
  );
};
