import React from 'react';

import { SortButton, ISortButton } from './components/SortButton';
import { Heading } from './components/Heading';
import { ISubscribe, Subscribe } from './components/Subscribe';
import { EOrdering } from '../../repositories/journal/v1/get-comments';
import * as s from './PostCommentsHeader.css';

export interface IPostCommentsHeader {
  commentsCount: number;
  isUserAuthenticated: boolean;
  isSubscriptionChecked: boolean;
  onSubscribeToComments: ISubscribe['onSubscribe'];
  onUnsubscribeFromComments: ISubscribe['onUnsubscribe'];
  userEmail?: string;
  ordering?: EOrdering;
  isDisabled?: boolean;
  onSort?: ISortButton['onClick'];
}

/**
 * Шапка листинга комментариев
 */
export const PostCommentsHeader = ({
  commentsCount,
  onSubscribeToComments,
  onUnsubscribeFromComments,
  ordering,
  isDisabled,
  onSort,
  ...otherSubscribeProps
}: IPostCommentsHeader) => {
  return (
    <div className={s['wrapper']}>
      <Heading count={commentsCount} />

      {onSort && commentsCount && (
        <SortButton onClick={(...args) => onSort(...args)} value={ordering} isDisabled={isDisabled} />
      )}

      <Subscribe
        onSubscribe={onSubscribeToComments}
        onUnsubscribe={onUnsubscribeFromComments}
        isDisabled={isDisabled}
        {...otherSubscribeProps}
      />
    </div>
  );
};
