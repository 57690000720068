import React, { forwardRef } from 'react';
import { Switch } from '@cian/ui-kit';

import * as s from './PostCommentsNotifySwitcher.css';

export interface IPostCommentsNotifySwitcher {
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>, value: boolean): void;
}

/**
 * Переключатель для отправки оповещений о новых комментах
 */
export const PostCommentsNotifySwitcher = forwardRef<HTMLLabelElement, IPostCommentsNotifySwitcher>(
  ({ isChecked, isDisabled, onChange }, ref) => {
    return (
      <div className={s['wrapper']}>
        <Switch
          ref={ref}
          label={'Оповещать о новых комментариях'}
          checked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
        />
      </div>
    );
  },
);

PostCommentsNotifySwitcher.displayName = 'PostCommentsNotifySwitcher';
