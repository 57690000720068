import { ITypedReduxAction } from '../../../types/redux/actionType';
import { ICommentParent, IComment } from '../../../types/commentList';

export enum EAddCommentActionType {
  AppendSucceed = 'postComments/addComment/appendSucceed',
}

export interface IAddCommentAppendSucceedParams {
  comment: ICommentParent | IComment;
  parentCommentId?: number | null;
}

export type TAddCommentAppendSucceed = ITypedReduxAction<
  EAddCommentActionType.AppendSucceed,
  IAddCommentAppendSucceedParams
>;

export type TAddComment = TAddCommentAppendSucceed;
