import * as React from 'react';
import { IIconProps } from '@cian/ui-kit/icons/internal/types';

export const IconSocialDislikeOff16 = ({ color }: IIconProps) => {
  const currentColor = color ? `var(--${color})` : 'currentColor';

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6500_35491)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.79016 15.1053C8.42024 15.6641 7.79543 16 7.12609 16H6.51638C5.965 16 5.51802 15.5523 5.51802 15V11H2.49874C1.08034 11 0.1142 9.56014 0.649907 8.24464L2.93945 2.62232C3.09271 2.24599 3.45811 2 3.86387 2H11.5082V11L8.79016 15.1053ZM9.50819 10.3979L7.51802 13.4039V9H2.50178L2.50221 8.99894L4.5379 4H9.50819V10.3979ZM13.5033 2H13.5V11H15.5016H15.5049C16.0548 10.9982 16.5 10.5512 16.5 10V3C16.5 2.44881 16.0548 2.00177 15.5049 2M15.5016 2H13.5033H15.5016Z"
          fill={currentColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_6500_35491">
          <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 -1 16.5 16)" />
        </clipPath>
      </defs>
    </svg>
  );
};
