import React from 'react';
import { Outside, IOutsideProps } from '@cian/ui-kit';

import * as s from './PopoverContent.css';

export interface IPopoverContent {
  children: React.ReactNode;
  isOutsideActive?: IOutsideProps['active'];
  onOutsideClick?: IOutsideProps['onOutside'];
  insideRefs?: IOutsideProps['insideRefs'];
}

/**
 * Декоратор контента поповера
 */
export const PopoverContent = ({ children, isOutsideActive, insideRefs, onOutsideClick }: IPopoverContent) => (
  <Outside active={isOutsideActive} onOutside={() => onOutsideClick && onOutsideClick()} insideRefs={insideRefs}>
    <div className={s['wrapper']}>{children}</div>
  </Outside>
);
