import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IPost } from '../../../types/post';

export enum EGetPostActionType {
  Loading = 'post/getPost/loading',
  Succeed = 'post/getPost/succeed',
  Failed = 'post/getPost/failed',
}

export type TGetPostLoading = ITypedReduxAction<EGetPostActionType.Loading>;
export type TGetPostSucceed = ITypedReduxAction<EGetPostActionType.Succeed, IPost>;
export type TGetPostFailed = ITypedReduxAction<EGetPostActionType.Failed>;

export type TGetPost = TGetPostLoading | TGetPostSucceed | TGetPostFailed;
