import { IHTTPCompletedResponse } from '@cian/http-api';

import { TThunkAction } from '../../../types/redux';
import { requestHideComment, TResponses } from '../../../services/postComments/hideComment';
import { throwError } from '../../../utils';

export const hideComment = (id: number): TThunkAction<Promise<boolean>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger, config } = context;

    try {
      const { responseStatusCode: statusCode }: IHTTPCompletedResponse<TResponses> = await requestHideComment({
        httpApi,
        bodyParameters: {},
        queryParameters: {
          id,
        },
        config: {
          browserUrl: config.get<string>('backend-content.browserUrl') || '',
        },
      });

      if (statusCode !== 200) {
        throwError({ message: 'Не удалось скрыть комментарий', statusCode, domain: 'actions.hideComment' });

        return false;
      }

      return true;
    } catch (e) {
      logger.error(e, {
        domain: 'actions.hideComment',
      });

      return false;
    }
  };
};
