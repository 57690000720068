import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ISetCommentParam, setCommentParam } from '../../../../actions/postComments';
import { TThunkDispatch } from '../../../../types/redux';

export function useSetCommentParam() {
  const dispatch = useDispatch<TThunkDispatch>();

  /**
   * Обновляет параметр коммента
   */
  return useCallback(async (params: ISetCommentParam) => dispatch(setCommentParam(params)), [dispatch]);
}
