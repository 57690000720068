import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IUserDataItemSchema } from '../../../repositories/journal/entities/user_data_item/UserDataItemSchema';
import { ELikeType } from '../../../types/likes';

export enum EGetLikedUsersActionType {
  Loading = 'postComments/getLikedUsers/loading',
  Succeed = 'postComments/getLikedUsers/succeed',
  Failed = 'postComments/getLikedUsers/failed',
}

export interface IGetLikedUsersSucceedParams {
  commentId: number;
  items: IUserDataItemSchema[];
  likeType: ELikeType;
}

export type TGetLikedUsersParams = Pick<IGetLikedUsersSucceedParams, 'commentId' | 'likeType'>;

export type TGetLikedUsersLoading = ITypedReduxAction<EGetLikedUsersActionType.Loading, TGetLikedUsersParams>;
export type TGetLikedUsersSucceed = ITypedReduxAction<EGetLikedUsersActionType.Succeed, IGetLikedUsersSucceedParams>;
export type TGetLikedUsersFailed = ITypedReduxAction<EGetLikedUsersActionType.Failed, TGetLikedUsersParams>;

export type TGetLikedUsers = TGetLikedUsersLoading | TGetLikedUsersSucceed | TGetLikedUsersFailed;
