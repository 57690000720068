import { ITypedReduxAction } from '../../../types/redux/actionType';

export enum EEditCommentActionType {
  Succeed = 'postComments/editComment/succeed',
}

export interface IEditCommentSucceedParams {
  comment: string;
  id: number;
}

export type TEditCommentSucceed = ITypedReduxAction<EEditCommentActionType.Succeed, IEditCommentSucceedParams>;

export type TEditComment = TEditCommentSucceed;
