import * as React from 'react';
import { IIconProps } from '@cian/ui-kit/icons/internal/types';

export const IconActionDelete16 = ({ color }: IIconProps) => {
  const currentColor = color ? `var(--${color})` : 'currentColor';

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C4 0.89543 4.89543 0 6 0H10C11.1046 0 12 0.89543 12 2V3H15V5H1V3H4V2ZM10 2V3H6V2H10Z"
        fill={currentColor}
      />
      <path d="M7 7V12H9V7H7Z" fill={currentColor} />
      <path d="M3 6V14C3 15.1046 3.89543 16 5 16H11C12.1046 16 13 15.1046 13 14V6H11V14H5V6H3Z" fill={currentColor} />
    </svg>
  );
};
