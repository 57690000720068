import { ITypedReduxAction } from '../../../types/redux/actionType';

export enum EAddLikeActionType {
  Succeed = 'postComments/addLike/succeed',
}

export interface IAddLikeSucceedParams {
  commentId: number;
  dislikesCount: number;
  likesCount: number;
}

export type TAddLikeAppendSucceed = ITypedReduxAction<EAddLikeActionType.Succeed, IAddLikeSucceedParams>;

export type TAddLike = TAddLikeAppendSucceed;
