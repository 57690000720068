/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TCommentCheckUserSubscriptionModel,
  ICommentCheckUserSubscriptionRequest,
  IMappers,
  ICommentCheckUserSubscriptionResponse,
  ICommentCheckUserSubscriptionResponseError,
  TCommentCheckUserSubscriptionResponse,
} from './types';

const defaultConfig: TCommentCheckUserSubscriptionModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'content-subscriptions',
  pathApi: '/v1/comment/check-user-subscription/',
  hostType: 'api',
} as TCommentCheckUserSubscriptionModel;

function createCommentCheckUserSubscriptionModel(
  parameters: ICommentCheckUserSubscriptionRequest,
): TCommentCheckUserSubscriptionModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICommentCheckUserSubscriptionOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ICommentCheckUserSubscriptionRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchCommentCheckUserSubscription<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ICommentCheckUserSubscriptionOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TCommentCheckUserSubscriptionResponse
> {
  const { response, statusCode } = await httpApi.fetch(createCommentCheckUserSubscriptionModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICommentCheckUserSubscriptionResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ICommentCheckUserSubscriptionResponseError);
    }
  }

  return { response, statusCode } as TCommentCheckUserSubscriptionResponse;
}

export { defaultConfig, createCommentCheckUserSubscriptionModel, fetchCommentCheckUserSubscription };
