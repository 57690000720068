import React, { useCallback, useRef, useState } from 'react';
import { IIconProps } from '@cian/ui-kit/icons/internal';
import { CustomTooltip } from '@cian/ui-kit';

import { IconWithCount } from '../../../IconWithText';
import * as s from './Like.css';

export interface ILike {
  count: number;
  IconComponent: React.JSXElementConstructor<IIconProps>;
  tooltipContent?: React.ReactNode;
  isDisabled?: boolean;
  onClick?(e: React.MouseEvent): void;
  onToggleTooltip?(isShown: boolean): void;
}

/**
 * Кнопки лайк/дизлайк
 */
export const Like = ({ count, IconComponent, tooltipContent, isDisabled, onClick, onToggleTooltip }: ILike) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isShown, setIsShown] = useState(false);

  const handleToggleTooltip = useCallback(
    (isShown: boolean) => {
      if (isDisabled || !tooltipContent) {
        return;
      }

      setIsShown(isShown);

      if (onToggleTooltip) {
        onToggleTooltip(isShown);
      }
    },
    [isDisabled, onToggleTooltip, tooltipContent],
  );

  return (
    <div
      className={s['wrapper']}
      ref={ref}
      onMouseEnter={() => handleToggleTooltip(true)}
      onMouseLeave={() => handleToggleTooltip(false)}
    >
      <IconWithCount
        count={count}
        icon={
          <div className={`${s['icon-wrapper']} ${!isDisabled && onClick ? s['_clickable'] : ''}`}>
            <IconComponent
              onClick={e => {
                if (!onClick || isDisabled) {
                  return;
                }

                onClick(e);
              }}
            />
          </div>
        }
      />

      {tooltipContent && (
        <CustomTooltip
          anchorRef={ref}
          content={tooltipContent}
          flip
          arrow
          maxWidth={450}
          open={isShown}
          placement="bottom"
          portal
          preventOverflow
          size="S"
          onClose={() => {}}
          theme={'white'}
        />
      )}
    </div>
  );
};
