import React, { useCallback, useRef, useState } from 'react';
import { Button, Checkbox, FormField, Input, UIHeading4 } from '@cian/ui-kit';

import { IPopoverContent, PopoverContent } from '../../../PopoverContent';
import { useEventListener, validateEmail } from '../../../../utils';
import * as s from './SubscribeForm.css';

export interface ISubscribeFormSubmit {
  email: string;
  isChecked: boolean;
}

export interface ISubscribeForm extends Pick<IPopoverContent, 'isOutsideActive' | 'onOutsideClick' | 'insideRefs'> {
  onSubmit({ email, isChecked }: ISubscribeFormSubmit): void;
  isDisabled?: boolean;
}

/**
 * Форма подписки
 */
export const SubscribeForm = ({ isDisabled, onSubmit, ...popoverContentProps }: ISubscribeForm) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  /**
   * Валидирует и отправляет форму
   */
  const handleSubmit = useCallback(
    (e: React.MouseEvent | KeyboardEvent) => {
      e.preventDefault();

      if (!validateEmail(email)) {
        setErrorMessage('Некорректный email');

        return;
      }

      onSubmit({ email, isChecked });
    },
    [email, isChecked, onSubmit],
  );

  /**
   * Сохраняет вводимый email и сбрасывает ошибку валидации
   */
  const handleChange = useCallback((value: string) => {
    setErrorMessage('');
    setEmail(value);
  }, []);

  /**
   * Вызывает обработчик отправки формы при нажатии на Enter
   */
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        handleSubmit(e);
      }
    },
    [handleSubmit],
  );

  useEventListener('keydown', handleKeyDown, inputRef);

  return (
    <PopoverContent {...popoverContentProps}>
      <div className={s['wrapper']}>
        <UIHeading4>Оставьте имейл и мы пришлём новые комментарии к&nbsp;этой публикации</UIHeading4>

        <form className={s['form-wrapper']}>
          <FormField withoutMargin errorMessage={errorMessage}>
            <Input
              ref={inputRef}
              value={email}
              size={'M'}
              invalid={Boolean(errorMessage)}
              placeholder="Ваш e–mail"
              disabled={isDisabled}
              onFocus={() => setErrorMessage('')}
              onChange={(e, value) => handleChange(value)}
            />
          </FormField>

          <Checkbox
            label={'Получать новости от Циан'}
            checked={isChecked}
            disabled={isDisabled}
            onChange={(e, value) => setIsChecked(value)}
          />

          <Button theme={'fill_primary'} size={'M'} disabled={isDisabled || !email} onClick={handleSubmit}>
            Отправить
          </Button>
        </form>
      </div>
    </PopoverContent>
  );
};
