/* eslint-disable */
//tslint:disable

import { IUserDataItemSchema } from '../user_data_item/UserDataItemSchema';
import { ICommentSchema } from './CommentSchema';

export interface ICommentParentSchema {
  /** Информация об авторе **/
  authorObject?: IUserDataItemSchema | null;
  /** Лучший совет **/
  bestAdvice: boolean;
  /** Комментарий **/
  comment: string;
  /** Комментарии **/
  comments?: ICommentSchema[] | null;
  /** Дата публикации **/
  datePublish: string;
  /** Количество дизлайков **/
  dislikesCount: number;
  /** ID сущности **/
  id: number;
  /** Количество лайков **/
  likesCount: number;
  /** Статус **/
  status: EStatus;
  /** ID пользователя **/
  userId: number;
}

export enum EStatus {
  /** Проверено **/
  Checked = 'checked',
  /** Отклонено **/
  Decline = 'decline',
  /** Скрыто **/
  Hidden = 'hidden',
  /** На премодерации **/
  Premoderation = 'premoderation',
  /** Удалено **/
  Removed = 'removed',
  /** Не проверено **/
  Unchecked = 'unchecked',
}
