import * as React from 'react';
import { Like16 as IconSocialLikeOn16, Dislike16 as IconSocialDislikeOn16 } from '@cian/ui-kit';

import { IconWithCount } from '../../../../components/IconWithText';
import { IconMessage16 } from '../../../../components/Icons';
import * as s from './Counts.css';

interface ICounts {
  likesCount?: number;
  dislikesCount?: number;
  commentsCount?: number;
}

/**
 * Табы авторов и тегов
 */
export const Counts = ({ likesCount, dislikesCount, commentsCount }: ICounts) => {
  return (
    <div className={s['wrapper']}>
      <div className={s['likes-wrapper']}>
        {likesCount ? <IconWithCount count={likesCount} icon={<IconSocialLikeOn16 />} /> : null}
        {dislikesCount ? <IconWithCount count={dislikesCount} icon={<IconSocialDislikeOn16 />} /> : null}
      </div>

      {commentsCount ? <IconWithCount count={commentsCount} icon={<IconMessage16 />} /> : null}
    </div>
  );
};
