import React from 'react';

import { ELikeType } from '../../../../types/likes';
import { TLikedUsersListState } from '../../../../types/commentList';
import { LikesModalTabContent as ModalTabContent } from '../LikesModalTabContent';
import * as s from './LikesModalTabsContent.css';

export interface ILikesModalTabsContent {
  activeTab: ELikeType;
  likedUsers?: TLikedUsersListState;
  dislikedUsers?: TLikedUsersListState;
  onTabShown?(likeType: ELikeType): void;
}

export const LikesModalTabsContent = ({ activeTab, likedUsers, dislikedUsers, onTabShown }: ILikesModalTabsContent) => {
  return (
    <div className={s['wrapper']}>
      {activeTab === ELikeType.Like && (
        <ModalTabContent {...likedUsers} onTabShown={onTabShown && (() => onTabShown(ELikeType.Like))} />
      )}

      {activeTab === ELikeType.Dislike && (
        <ModalTabContent {...dislikedUsers} onTabShown={onTabShown && (() => onTabShown(ELikeType.Dislike))} />
      )}
    </div>
  );
};
