import { ICommentParent, IComment } from '../../../../types/commentList';
import { findIndexesByIdRecursive } from '../../../../utils';

interface ISpliceCommentDataOptions {
  isParentItemsOnly?: boolean;
}

interface ISpliceCommentData<T extends ICommentParent | IComment> {
  comments: ICommentParent[];
  id: T['id'];
  data?: ((data: T) => Partial<T>) | Partial<T>;
  options?: ISpliceCommentDataOptions;
}

/**
 * Обновляет поля комментария внутри родительской или дочерней коллекций.
 * Удаляет родительский или дочерний комментарий.
 * Добавляет комментарий в родительскую или дочернюю коллекции.
 */
export const spliceCommentData = ({
  comments,
  id,
  data,
  options = {},
}: ISpliceCommentData<ICommentParent | IComment>) => {
  const { isParentItemsOnly } = options;
  const parentComments: ICommentParent[] = JSON.parse(JSON.stringify(comments));
  const [parentIndex, childIndex] = findIndexesByIdRecursive(
    isParentItemsOnly ? parentComments.map(item => ({ ...item, comments: undefined })) : parentComments,
    id,
    { itemsName: 'comments' },
  );

  /**
   * Возвращает исходную коллекцию, если ничего не нашел
   */
  if (parentIndex === undefined) {
    return parentComments;
  }

  /**
   * Обновляет/удаляет коммент к комменту
   */
  if (childIndex !== undefined) {
    const childComments = parentComments[parentIndex]?.comments as IComment[];

    childComments.splice(
      childIndex,
      1,
      ...(data
        ? [
            {
              ...childComments[childIndex],
              ...(typeof data === 'function' ? data(childComments[childIndex]) : data),
            },
          ]
        : []),
    );

    parentComments.splice(parentIndex, 1, {
      ...parentComments[parentIndex],
      comments: childComments,
    });

    return parentComments;
  }

  /**
   * Обновляет/удаляет коммент к статье
   */
  parentComments.splice(
    parentIndex,
    1,
    ...(data
      ? [
          {
            ...parentComments[parentIndex],
            ...(typeof data === 'function' ? data(parentComments[parentIndex]) : data),
          },
        ]
      : []),
  );

  return parentComments;
};
