/* eslint-disable */
import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';

import {
  TAddLikeModel,
  IAddLikeRequest,
  TAddLikeResponse,
} from './types';

const defaultConfig: TAddLikeModel = {
  apiType: 'public',
  requestType: 'formData',
  assertStatusCodes: [201, 400],
  method: 'POST',
  microserviceName: 'backend-content',
  pathApi: '/v1/add-like',
  hostType: 'api',
};

function createAddLikeModel(parameters: IAddLikeRequest): TAddLikeModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAddLikeOptions<TResponse201, TResponse400> {
  httpApi: IHttpApi;
  parameters: IAddLikeRequest;
  config?: IHttpApiFetchConfig;
}

async function fetchAddLike<TResponse201, TResponse400>({
  httpApi,
  parameters,
  config,
}: IAddLikeOptions<TResponse201, TResponse400>): Promise<
  TResponse201 | TResponse400 | TAddLikeResponse
  > {
  return await httpApi.fetch(createAddLikeModel(parameters), { ...config, requestConfig: { withCredentials: true } });
}

export { defaultConfig, createAddLikeModel, fetchAddLike };
